var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "erp-header flex p-lr-40 font-size-18 flex-jcsb" },
    [
      _c("div", { staticClass: "head-warp flex flex-aic" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "head-nav-list flex flex-aic" },
          _vm._l(_vm.menuTree, function (item) {
            return _c(
              "li",
              {
                key: item.id,
                staticClass: "nav-item p-lr-20 flex flex-aic",
                on: {
                  mouseenter: function ($event) {
                    return _vm.menuEnter(item.id)
                  },
                  mouseleave: _vm.menuLeave,
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "pointer",
                    on: {
                      click: function ($event) {
                        return _vm.goRoute(item.uri)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _vm._v(" "),
                _vm._m(1, true),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "head-right flex flex-aic" }, [
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pemenu-warp flex flex-aic" },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "bottom-end",
                  width: "200",
                  trigger: "hover",
                },
              },
              [
                _c("div", { staticClass: "menu-el-popover" }, [
                  _c(
                    "p",
                    {
                      staticClass: "pointer line-text",
                      on: { click: _vm.logout },
                    },
                    [_vm._v("登出")]
                  ),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "el-icon-setting pointer",
                  attrs: { slot: "reference" },
                  slot: "reference",
                }),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isMenu
        ? _c(
            "div",
            {
              staticClass: "head-nav-menus",
              on: {
                mouseenter: function ($event) {
                  return _vm.menuEnter()
                },
                mouseleave: _vm.menuLeave,
              },
            },
            [
              _c("Menus", {
                attrs: { list: _vm.activeMenus },
                on: { goRoute: _vm.goRoute },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          attrs: { src: require("@/assets/erplogo.svg"), width: "120" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav-line" }, [
      _c("div", { staticClass: "nav-line-full" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-r-20" }, [
      _c("span", { staticClass: "el-icon-user-solid pointer" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-r-20" }, [
      _c("span", { staticClass: "el-icon-headset pointer" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }