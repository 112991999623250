var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-jcc p-t-80" }, [
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("p", { staticClass: "p-40" }, [
          _vm._v(
            "当前页面没有权限，请找组长开权限。若组长没有权限，再找技术哥哥处理哈"
          ),
        ]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: require("@/assets/images/403.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }