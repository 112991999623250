import "core-js/modules/es6.number.constructor";
import _typeof from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";

var esc = function esc(param) {
  return fixedEncodeURIComponent(param).replace(/[!'()*]/g, escape).replace(/%20/g, '+');
};

var isNumeric = function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*~]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase();
  });
}

var cleanArray = function cleanArray(actual) {
  var newArray = new Array();

  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }

  return newArray;
};

export default function httpBuildQuery(queryData, numericPrefix, argSeparator, tempKey) {
  numericPrefix = numericPrefix || null;
  argSeparator = argSeparator || '&';
  tempKey = tempKey || null;

  if (!queryData) {
    return '';
  }

  var query = Object.keys(queryData).map(function (k) {
    var res;
    var key = k;

    if (tempKey) {
      key = tempKey + '[' + key + ']';
    }

    if (_typeof(queryData[k]) === 'object' && queryData[k] !== null) {
      res = httpBuildQuery(queryData[k], null, argSeparator, key);
    } else {
      if (numericPrefix) {
        key = isNumeric(key) ? numericPrefix + Number(key) : key;
      }

      var val = queryData[k];
      val = val === true ? '1' : val;
      val = val === false ? '0' : val;
      val = val === 0 ? '0' : val;
      val = val || '';
      res = esc(key) + '=' + esc(val);
    }

    return res;
  });
  return cleanArray(query).join(argSeparator).replace(/[!'()*]/g, '');
}