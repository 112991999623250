import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { categorytree } from '@/api/product';
export default {
  name: 'BaseSearchDate',
  inheritAttrs: false,
  props: {
    start: {
      type: String,
      default: ''
    },
    end: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      start_time: '',
      end_time: ''
    };
  },
  watch: {
    start: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (!val) {
          this.start_time = '';
        } else {
          this.start_time = val ? val : null;
        }
      }
    },
    end: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (!val) {
          this.end_time = '';
        } else {
          this.end_time = val ? val : null;
        }
      }
    }
  },
  created: function created() {},
  methods: {
    timeChange: function timeChange(key) {
      var _this = this;

      this.$nextTick(function () {
        if (key == 'start_time') {
          _this.$emit('update:start', _this.start_time);
        } else {
          var newTime = _this.end_time;

          if (_this.end_time) {
            newTime = _this.end_time.replace('00:00:00', '23:59:59');
          }

          _this.$emit('update:end', newTime);
        }
      });
    },
    pickerStartDate: function pickerStartDate() {
      var vm = this;
      return {
        disabledDate: function disabledDate(time) {
          var end_time = vm.end_time;

          if (end_time) {
            return time.getTime() > new Date(end_time).getTime();
          } else {
            return false;
          }
        }
      };
    },
    pickerEndDate: function pickerEndDate() {
      var vm = this;
      return {
        disabledDate: function disabledDate(time) {
          var start_time = vm.start_time;

          if (start_time) {
            return new Date(start_time).getTime() > time.getTime();
          } else {
            return false;
          }
        }
      };
    }
  }
};