/**
 * TODO 这里放一些常量  避免冲突的那种
 */
//token
export var TOKEN_KEY = 'ERP_TOKEN'; //secret 秘钥

export var SECRET = 'ERP_SECRET'; //refresh_token //刷新toekn需要的key

export var REFRESH_TOKEN = 'ERP_REFRESH_TOKEN'; //TENANT_ID //刷新toekn需要的TENANT_ID

export var TENANT_ID = 'TENANT_ID'; //USER_NAME //刷新toekn需要的USER_NAME

export var USER_NAME = 'USER_NAME';
export var CACHE_TAB = 'CACHE_TABLE_v1'; // 表格自定义列选择，浏览器缓存key值 begin
// 因为key值不能重复，所有全部都在这里定义，方便维护
// 商品管理

export var CACHE_TH_PRODUCT_LIST = 'product-list';
export var CACHE_TH_PRODUCT_LIST_QUEUE = 'product-queue-list';
export var CACHE_TH_PRODUCT_IMPORT_LIST = 'product-import-list';
export var CACHE_TH_PRODUCT_BRAND = 'product-brand';
export var CACHE_TH_PRODUCT_SPEC_LIST = 'product-spec-list';
export var CACHE_TH_PRODUCT_ATTR_CFG = 'product-attr-cfg';
export var CACHE_TH_PRODUCT_TAX_CFG = 'product-tax-cfg';
export var CACHE_TH_PRODUCT_MODEL_LIBRARY = 'product-model-library';
export var CACHE_TH_PRODUCT_MODEL_FITTING = 'product-model-fitting';
export var CACHE_TH_PRODUCT_PACKAGING_ASK = 'product-packaging-ask';
export var CACHE_TH_PRODUCT_MARK_UP_SETTING = 'product-mark-up-setting';
export var CACHE_TH_PRODUCT_ACCESSORIES_LIST = 'product-accessories-list';
export var CACHE_TH_PRODUCT_NEW_ATTRIBUTE = 'product-new-attribute'; // 商品属性一类属性页面

export var CACHE_TH_PRODUCT_LEVEL2_CATEGORY_NEW_ATTRIBUTE = 'level2-category-new-attribute-list'; // 商品属性二类属性页面
// 订单

export var CACHE_TH_ORDER_LIST = 'order-order-list';
export var CACHE_TH_ORDER_ABERRANT_VERIFY = 'order-aberrant-verify'; // 前缀，实际的存储在localStroage的key是order-issue-list-[状态码]

export var CACHE_TH_ORDER_ISSUE_LIST_PREFIX = 'order-issue-list-'; // 前缀，实际的存储在localStroage的key是order-distribution-list-[状态码]

export var CACHE_TH_ORDER_DISTRIBUTION_LIST_PREFIX = 'order-distribution-list-';
export var CACHE_TH_ORDER_SHIP_LIST = 'order-ship-list';
export var CACHE_TH_ORDER_VIRTUAL_SHIPPING = 'order-virtual-shipping';
export var CACHE_TH_ORDER_TRANSFER_LIST = 'order-transfer-list';
export var CACHE_TH_ORDER_MAPPING_PRODUCT = 'order-mapping-product';
export var CACHE_TH_ORDER_OUT_PRODUCT = 'order-out-product';
export var CACHE_TH_ORDER_ISSUE_RULE = 'order-issue-rule';
export var CACHE_TH_ORDER_LOGISTICS_RULE = 'order-logistics-rule';
export var CACHE_TH_ORDER_BLACKLIST = 'order-blacklist';
export var CACHE_TH_ORDER_CUSTOMER_LIST = 'order-customer-list';
export var CACHE_TH_ORDER_RECEIPT_MANAGE = 'order-receipt-manage';
export var CACHE_TH_ORDER_EXPORT_LIST = 'order-export-list';
export var CACHE_TH_SERVICE_REASON_MAP_LIST = 'service-reason-map-list'; // 客户原因列表

export var CACHE_TH_COUNTRY_ROI_STAT_SUMMARY = 'order-country-roi-stat-summary'; // 国家ROI汇总

export var CACHE_TH_COUNTRY_ROI_STAT_list = 'order-country-roi-stat-list'; // 国家ROI明细

export var CACHE_TH_BRAND_GRADE_PROFIT_LIST = 'order-brand-grade-profit-list'; // 毛利报表

export var CACHE_TH_CATEGORY_SALES_INVENTORY_REPORT = 'order-category-sales-inventory-report'; // 分类销存报表

export var CACHE_TH_ORDER_AFTER_SALE_SUMMARY = 'order-after-sale-summary';
export var CACHE_TH_ORDER_AFTER_SALE_DETAIL = 'order-after-sale-detail';
export var CACHE_TH_ORDER_AFTER_SALE_REASON = 'order-after-sale-reason'; // 仓库

export var CACHE_TH_STOCK_RECEIPT_LIST = 'stock-receipt-list'; // 收货单列表

export var CACHE_TH_STOCK_RECEIPT_DETAIL_LIST_VIEW = 'stock-receipt-detail-list-view'; // 收货单明细-只能查看

export var CACHE_TH_STOCK_RECEIPT_DETAIL_LIST_EDIT = 'stock-receipt-detail-list-edit'; // 收货单明细-可以编辑

export var CACHE_TH_STOCK_QC_LIST = 'stock-qc-list'; // 质检单列表

export var CACHE_TH_STOCK_QC_IN_VIEW = 'stock-qc-in-view'; // 质检明细表-只能查看

export var CACHE_TH_STOCK_QC_IN_EDIT = 'stock-qc-in-edit'; // 质检明细表-可以编辑

export var CACHE_TH_STOCK_QC_IMPROVE = 'stock-qc-improve'; // 质检改善单列表

export var CACHE_TH_STOCK_PUT_SHELF_LIST = 'stock-put-shelf-list'; // 上架单列表

export var CACHE_TH_STOCK_PUT_SHELF_DETAIL_VIEW = 'stock-put-shelf-detail-view'; // 上架单明细-只读

export var CACHE_TH_STOCK_PUT_SHELF_DETAIL_EDIT = 'stock-put-shelf-detail-edit'; // 上架单明细-可编辑

export var CACHE_TH_STOCK_OUT_STOCK_PREFIX = 'stock-out-stock-';
export var CACHE_TH_STOCK_OUT_STOCK_DETAIL = 'stock-out-stock-detail';
export var CACHE_TH_STOCK_OUT_STOCK_ERROR_LIST = 'out-stock-error-list';
export var CACHE_TH_STOCK_PICK_LIST = 'stock-pick-list'; //拣货单列表

export var CACHE_TH_STOCK_PICK_DETAIL = 'stock-pick-detail'; //拣货单明细

export var CACHE_TH_STOCK_SECOND_PICK = 'stock-second-pick'; //二次分拣
// export const CACHE_TH_STOCK_OUT_PACKAGE = 'stock-out-package';

export var CACHE_TH_STOCK_INVENTORY_LIST = 'stock-inventory-list';
export var CACHE_TH_STOCK_INVENTORY_DETAIL = 'stock-inventory-detail';
export var CACHE_TH_STOCK_WAREHOUSE_MOVE = 'stock-warehouse-move';
export var CACHE_TH_STOCK_SHELF_ALLOT = 'stock-shelf-allot';
export var CACHE_TH_STOCK_SHELF_RECYCLE = 'stock-shelf-recycle';
export var CACHE_TH_STOCK_SUPPLY_SKU = 'stock-supply-sku';
export var CACHE_TH_STOCK_WAREHOUSE_LIST_PRODUCT = 'stock-warehouse-list-product';
export var CACHE_TH_STOCK_WAREHOUSE_LIST_STOCK = 'stock-warehouse-list-stock';
export var CACHE_TH_STOCK_SITE_LIST = 'stock-site-list';
export var CACHE_TH_STOCK_MANAGEMENT_LIST = 'stock-management-list';
export var CACHE_TH_STOCK_WAREHOUSE_AREA = 'stock-warehouse-area';
export var CACHE_TH_STOCK_QI_BAD_PROCESS_LIST = 'stock-qi-bad-process-list';
export var CACHE_TH_INVOICING_LIST_SUMMARY_LIST = 'invoicing-list-summary-list';
export var CACHE_TH_INVOICING_LIST_DETAILS_LIST = 'invoicing-list-details-list';
export var CACHE_TH_INVOICING_LIST_STOCK_LIST = 'invoicing-list-stock-list';
export var CACHE_TH_INVOICING_LIST_CYCLE_LIST = 'invoicing-list-cycle-list';
export var CACHE_TH_STOCK_ACC_OUTBOUND_PREFIX = 'stock-acc-outbound-';
export var CACHE_TH_STOCK_SIGN_FOR_REPORT_LIST = 'stock-sign-for-report-list';
export var CACHE_TH_CATEGORY_STOCK_REPORT_LIST = 'category-stock-report-list'; // 机器人

export var CACHE_TH_ROBOTS_1688_SYNC = 'robots-1688-sync'; // 采购管理
// 前缀，实际的存储在localStroage的key是pms-stocking-plan-[状态码]

export var CACHE_TH_PMS_STOCKING_PLAN_PREFIX = 'pms-stocking-plan-';
export var CACHE_TH_PMS_PURCHASE_DEMAND_1 = 'pms-purchase-demand-1';
export var CACHE_TH_PMS_PURCHASE_DEMAND_2 = 'pms-purchase-demand-2';
export var CACHE_TH_PMS_PURCHASE_DEMAND_3 = 'pms-purchase-demand-3'; // 前缀，实际的存储在localStroage的key是pms-purchase-order-[状态码]

export var CACHE_TH_PMS_PURCHASE_ORDER_PREFIX = 'pms-purchase-order-'; // 前缀，实际的存储在localStroage的key是pms-purchase-payment-[状态码]

export var CACHE_TH_PMS_PURCHASE_PAYMENT_PREFIX = 'pms-purchase-payment-';
export var CACHE_TH_PMS_PURCHASE_DIFFERENCE = 'pms-purchase-difference';
export var CACHE_TH_PMS_PURCHASE_DIFFERENCE_PRICE = 'pms-purchase-difference_price'; // 前缀，实际的存储在localStroage的key是pms-purchase-bad-process-[状态码]

export var CACHE_TH_PMS_PURCHASE_BAD_PROCESS_PREFIX = 'pms-purchase-bad-process-';
export var CACHE_TH_PMS_SUPPLIER_LIST = 'pms-supplier-list';
export var CACHE_TH_PMS_SKU_SUPPLY_MANAGE = 'pms-sku-supply-manage';
export var CACHE_TH_PMS_SUPPLY_MANAGE = 'pms-supply-manage';
export var CACHE_TH_PMS_SKC_BARGAIN = 'pms-skc-bargain'; // 财务管理
// 前缀，实际的存储在localStroage的key是finance-payment-approval-[状态码]

export var CACHE_TH_FINANCE_PAYMENT_APPROVAL_PREFIX = 'finance-payment-approval-';
export var CACHE_TH_FINANCE_REVENUE_DETAIL = 'finance-revenue-detail';
export var CACHE_TH_FINANCE_PRE_PAYMENT_APPROVAL_PREFIX = 'finance-pre-purchase-order';
export var CACHE_TH_FINANCE_PRE_PAYMENT_APPROVAL_PREFIX_DETAIL = 'finance-pre-purchase-order-detail';
export var CACHE_TH_FINANCE_COST_SETTING_COLLECTION = 'finance-cost-setting-collection';
export var CACHE_TH_FINANCE_COST_SETTING_REFUND = 'finance-cost-setting-refund';
export var CACHE_TH_FINANCE_COST_SETTING_PLATFORM = 'finance-cost-setting-platform';
export var CACHE_TH_FINANCE_PROFIT_ACCOUNTING = 'finance-profit-accounting';
export var CACHE_TH_FINANCE_MARKETING_COST = 'finance-marketing-cost';
export var CACHE_TH_FINANCE_LOGISTICS_FEE = 'finance-logistics-fee';
export var CACHE_TH_FINANCE_BANK_INFO = 'finance-bank-info';
export var CACHE_TH_FINANCE_RELATION_PAY_LIST = 'finance-relation-pay-list'; // 物流管理

export var CACHE_TH_USER_LOGISTICS = 'user-logistics';
export var CACHE_TH_USER_LOGISTICS_CHANNEL = 'user-logistics-channel';
export var CACHE_TH_USER_BASIS_CHANNEL = 'user-basis-channel';
export var CACHE_TH_LOGISTICS_FEE_CALCULATE = 'logistics-fee-calculate';
export var CACHE_TH_LOGISTICS_FEE_MANAGE = 'logistics-fee-manage';
export var CACHE_TH_LOGISTICS_PARTITION = 'logistics-partition';
export var CACHE_TH_LOGISTICS_VALUE_LIST = 'logistics-value-list';
export var CACHE_TH_LOGISTICS_COUNTRY_LIST = 'logistics-country-list';
export var CACHE_TH_LOGISTICS_PRICE = 'logistics-price'; // 刊登管理
// 前缀，实际的存储在localStroage的key是shopify-management-list-[状态码]

export var CACHE_TH_SHOPIFY_MANAGEMENT_LIST_PREFIX = 'shopify-management-list-'; // 前缀，实际的存储在localStroage的key是shopify-online-list-[状态码]

export var CACHE_TH_SHOPIFY_ONLINE_LIST_PREFIX = 'shopify-online-list-'; // 系统管理

export var CACHE_TH_USER_LIST = 'user-list';
export var CACHE_TH_USER_ROLES = 'user-roles';
export var CACHE_TH_USER_MENUS = 'user-menus';
export var CACHE_TH_USER_BASIS_ACCOUNT = 'user-basis-account';
export var CACHE_TH_USER_BASIS_PLATFORM = 'user-basis-platform';
export var CACHE_TH_USER_BASIS_COUNTRY = 'user-basis-country';
export var CACHE_TH_USER_BASIS_CURRENCY = 'user-basis-currency';
export var CACHE_TH_USER_BASIS_RATE = 'user-basis-rate'; // 表格自定义列选择，浏览器缓存key值 end