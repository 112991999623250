import { createRouteItem, layoutItem, createMeta } from "./unit.js";

var FinancePaymentApproval = function FinancePaymentApproval() {
  return import(
  /* webpackChunkName: 'finance-payment-approval' */
  '@/views/finance/payment-approval');
}; //付款管理


var FinanceRevenueDetail = function FinanceRevenueDetail() {
  return import(
  /* webpackChunkName: 'finance-revenue-detail' */
  '@/views/finance/revenue-detail');
}; //收支明细


var FinanceCostSetting = function FinanceCostSetting() {
  return import(
  /* webpackChunkName: 'finance-cost-setting' */
  '@/views/finance/cost-setting');
}; //成本配置


var FinanceProfitAccounting = function FinanceProfitAccounting() {
  return import(
  /* webpackChunkName: 'finance-profit-accounting' */
  '@/views/finance/profit-accounting');
}; //利润核算


var FinanceMarketingCost = function FinanceMarketingCost() {
  return import(
  /* webpackChunkName: 'finance-marketing-cost' */
  '@/views/finance/marketing-cost');
}; //营销费用


var FinanceLogisticsFee = function FinanceLogisticsFee() {
  return import(
  /* webpackChunkName: 'finance-marketing-cost' */
  '@/views/finance/logistics-fee');
}; //物流费用


var FinanceFeeEntry = function FinanceFeeEntry() {
  return import(
  /* webpackChunkName: 'finance-fee-entry' */
  '@/views/finance/fee-entry');
}; //费用录入


var FinanceBankInfo = function FinanceBankInfo() {
  return import(
  /* webpackChunkName: 'finance-bank-info' */
  '@/views/finance/bank-info');
}; //银行库


var FinanceRelationPay = function FinanceRelationPay() {
  return import(
  /* webpackChunkName: 'finance-bank-info' */
  '@/views/finance/payment-approval/relation-pay');
}; //银行库


var PMSPaymentSubject = function PMSPaymentSubject() {
  return import(
  /* webpackChunkName: 'finance-payment-subject' */
  '@/views/finance/payment-subject');
}; //付款主体库
//财务管理


export default layoutItem('/finance', [//付款管理
createRouteItem('payment-approval', 'finance-payment-approval', FinancePaymentApproval, createMeta('付款管理')), //支付详情
createRouteItem('relation-pay', 'finance-relation-pay', FinanceRelationPay, createMeta('支付详情', 'finance-payment-approval')), //收支明细
createRouteItem('revenue-detail', 'finance-revenue-detail', FinanceRevenueDetail, createMeta('收支明细')), //成本配置
createRouteItem('cost-setting', 'finance-cost-setting', FinanceCostSetting, createMeta('成本配置')), //利润核算
createRouteItem('profit-accounting', 'finance-profit-accounting', FinanceProfitAccounting, createMeta('利润核算')), //营销费用
createRouteItem('marketing-cost', 'finance-marketing-cost', FinanceMarketingCost, createMeta('营销费用录入')), //物流费用
createRouteItem('logistics-fee', 'finance-logistics-fee', FinanceLogisticsFee, createMeta('物流费用录入')), //费用录入
createRouteItem('fee-entry', 'finance-fee-entry', FinanceFeeEntry, createMeta('费用录入')), //银行库
createRouteItem('bank-info', 'finance-bank-info', FinanceBankInfo, createMeta('银行库')), //付款主体库
createRouteItem('payment-subject', 'finance-payment-subject', PMSPaymentSubject, createMeta('付款主体库'))]);