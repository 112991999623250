import Vue from 'vue';
import Vuex from 'vuex';
import app from "./modules/app";
import user from "./modules/user";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    user: user
  }
});
export default store;