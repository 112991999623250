//
//
//
//
//
//
//
//
//
//
export default {
  name: 'BaseCard',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};