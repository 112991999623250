import { createRouteItem, layoutItem, createMeta } from "./unit.js";

var publishShopifyCreate = function publishShopifyCreate() {
  return import(
  /* webpackChunkName: 'publish-shopify-create' */
  '@/views/publish/shopify/create');
}; //shopify添加listing


var publishShopifyManagement = function publishShopifyManagement() {
  return import(
  /* webpackChunkName: 'publish-shopify-management' */
  '@/views/publish/shopify/management');
}; //shopify刊登管理


var publishShopifyOnline = function publishShopifyOnline() {
  return import(
  /* webpackChunkName: 'publish-shopify-online' */
  '@/views/publish/shopify/online');
}; //在线listing


var publishShopifyOnlineLog = function publishShopifyOnlineLog() {
  return import(
  /* webpackChunkName: 'publish-shopify-online' */
  '@/views/publish/shopify/online-log');
}; //在线listing


var publishShopifyCollections = function publishShopifyCollections() {
  return import(
  /* webpackChunkName: 'publish-shopify-collections' */
  '@/views/publish/shopify/collection');
}; // 产品系列


var publishShopifyCollectionSort = function publishShopifyCollectionSort() {
  return import(
  /* webpackChunkName: 'publish-shopify-collections' */
  '@/views/publish/shopify/collection/sort');
}; // 产品排序


var publishShopifyVariation = function publishShopifyVariation() {
  return import(
  /* webpackChunkName: 'publish-shopify-online-variation' */
  '@/views/publish/shopify/online-variation');
}; // 产品系列
//刊登管理


export default layoutItem('/publish', [//shopify添加listing
createRouteItem('shopify-create', 'publish-shopify-create', publishShopifyCreate, createMeta('编辑平台商品资料', 'publish-shopify-online')), //shopify 刊登管理
createRouteItem('shopify-management', 'publish-shopify-management', publishShopifyManagement, createMeta('刊登管理')), //shopify 平台商品列表
createRouteItem('shopify-online', 'publish-shopify-online', publishShopifyOnline, createMeta('平台商品列表')), //shopify 平台商品日志列表
createRouteItem('shopify-online-log', 'publish-shopify-online-log', publishShopifyOnlineLog, createMeta('平台商品日志列表', 'publish-shopify-online')), //shopify 平台产品列表
createRouteItem('shopify-online-variation', 'publish-shopify-online-variation', publishShopifyVariation, createMeta('平台产品列表')), //shopify 产品系列
createRouteItem('shopify-collections', 'publish-shopify-collections', publishShopifyCollections, createMeta('产品系列')), //shopify 产品系列排序
createRouteItem('shopify-collection-sort', 'publish-shopify-collection-sort', publishShopifyCollectionSort, createMeta('产品系列排序', 'publish-shopify-collections'))]);