import { createRouteItem, layoutItem, createMeta } from "./unit.js";

var LogisticsCreateUserChannel = function LogisticsCreateUserChannel() {
  return import(
  /* webpackChunkName: 'logistics-create-user-channel' */
  '@/views/logistics/create-user-channel');
}; //创建租户物流渠道


var LogisticsFeeManage = function LogisticsFeeManage() {
  return import(
  /* webpackChunkName: 'logistics-fee-manage' */
  '@/views/logistics/fee-manage');
}; //费用类型管理


var LogisticsPartition = function LogisticsPartition() {
  return import(
  /* webpackChunkName: 'logistics-partition' */
  '@/views/logistics/partition');
}; //物流渠道分区管理


var LogisticsPrice = function LogisticsPrice() {
  return import(
  /* webpackChunkName: 'logistics-price' */
  '@/views/logistics/price');
}; //物流渠道价格管理


var LogisticsFeeCalculate = function LogisticsFeeCalculate() {
  return import(
  /* webpackChunkName: 'logistics-fee-calculate' */
  '@/views/logistics/fee-calculate');
}; //物流渠道模拟运费计算
//财务管理


export default layoutItem('/logistics', [//创建租户物流渠道
createRouteItem('create-user-channel', 'logistics-create-user-channel', LogisticsCreateUserChannel, createMeta('新增租户渠道', 'user-logistics-channel')), //费用类型管理
createRouteItem('fee-manage', 'logistics-fee-manage', LogisticsFeeManage, createMeta('费用类型管理')), //物流渠道分区管理
createRouteItem('partition', 'logistics-partition', LogisticsPartition, createMeta('物流渠道分区管理')), //物流渠道分区管理
createRouteItem('price', 'logistics-price', LogisticsPrice, createMeta('物流渠道价格管理')), //物流渠道模拟运费计算
createRouteItem('fee-calculate', 'logistics-fee-calculate', LogisticsFeeCalculate, createMeta('物流渠道模拟运费计算'))]);