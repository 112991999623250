var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crumbs-wrap flex flex-aic" },
    [
      _c(
        "el-breadcrumb",
        { staticClass: "breadcrumb-wrap", attrs: { separator: "/" } },
        _vm._l(_vm.crumbs, function (crumb, index) {
          return _c(
            "el-breadcrumb-item",
            { key: index },
            [
              _c(
                "el-link",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { underline: false },
                  on: {
                    click: function ($event) {
                      return _vm.goRoute(crumb.uri)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getRouteTitle(crumb, index)) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }