import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
export default {
  name: 'BaseTextOver',
  props: {
    num: {
      type: Number,
      default: 1
    },
    text: {
      type: [String, Number],
      default: ''
    }
  }
};