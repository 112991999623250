import "core-js/modules/es6.array.find";
//
//
//
import _ from 'lodash';
export default {
  name: 'BaseTag',
  props: {
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      flag: false,
      saveTags: [],
      invalidTag: ''
    };
  },
  mounted: function mounted() {
    this.setElements(this.value || []);
  },
  methods: {
    setElements: function setElements(loadTags) {
      var _this = this;

      var vm = this;

      var delVal = function delVal(tags) {
        var index = tags.indexOf(_this.invalidTag);

        if (~index) {
          $(_this.$el).find('textarea').tagEditor('removeTag', _this.invalidTag);
          tags.splice(index, 1);
          vm.$emit('input', tags);
        }
      };

      var opts = {
        initialTags: loadTags,
        // maxTags: 10,
        maxLength: 20,
        delimiter: "|",
        placeholder: "输入关键词后，回车键输入...",
        forceLowercase: false,
        onChange: function onChange(field, editor, tags) {
          // tags的值
          if (!vm.flag) {
            vm.$emit('input', tags);
            vm.$emit('change', tags);
          }

          if (vm.invalidTag) {
            delVal(tags);
          }

          setTimeout(function () {
            $(vm.$el).find('textarea').focus();
          }, 0);
        },
        beforeTagSave: function beforeTagSave(field, editor, tags, tag, val) {
          if (/(,|，)/.test(val)) {
            vm.flag = true;
            vm.invalidTag = val;
            vm.$message('不可输入带有中英文逗号的关键词');
            return;
          }

          if (tags.indexOf(val) > -1) {
            vm.$message('不可输入重复的关键词');
            vm.flag = true;
            return;
          }

          vm.flag = false;
          vm.invalidTag = '';
        }
      };
      opts = $.extend(opts, this.options);
      this.saveTags = loadTags;
      $(this.$el).find('textarea').tagEditor('destroy');
      $(this.$el).find('textarea').tagEditor(opts);

      if (!_.isEqual(vm.value, loadTags)) {
        vm.$emit('input', loadTags);
      }
    },
    getTags: function getTags() {
      var tags = $(this.$el).find('textarea').tagEditor('getTags')[0];
      return tags ? tags.tags : [];
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function handler(value) {
        if (Array.isArray(value)) {
          var tags = this.getTags();

          if (!_.isEqual(value, tags)) {
            this.setElements(value);
          }
        }
      }
    }
  }
};