import "core-js/modules/es6.function.name";
import Vue from 'vue';
/**
 * TODO 安装Element-ui
 */

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { SPLICE_CACHE_TABLE } from '@/store/types';
import { copyText } from '@/core/provide';
Vue.use(ElementUI, {
  size: 'small'
});
import { vueobj } from '@/core/provide';
import { BaseInput, BaseTable, BaseSelect, BaseMultiple, BaseProductCategory, BaseSearchDate, BaseSearchBrand, BaseTag, FormBox, FormItem, BaseSelectQuery, BaseDialog, BaseLink, BaseArea, BaseCard, BaseTextOver, SmallImgBox } from '@/components';
Vue.component(BaseMultiple.name, BaseMultiple);
Vue.component(BaseTable.name, BaseTable);
Vue.component(BaseSelect.name, BaseSelect);
Vue.component(BaseInput.name, BaseInput);
Vue.component(BaseProductCategory.name, BaseProductCategory);
Vue.component(BaseSearchDate.name, BaseSearchDate);
Vue.component(BaseSearchBrand.name, BaseSearchBrand);
Vue.component(BaseTag.name, BaseTag);
Vue.component(FormBox.name, FormBox);
Vue.component(FormItem.name, FormItem);
Vue.component(BaseSelectQuery.name, BaseSelectQuery);
Vue.component(BaseLink.name, BaseLink);
Vue.component(BaseArea.name, BaseArea);
Vue.component(BaseCard.name, BaseCard);
Vue.component(BaseTextOver.name, BaseTextOver);
Vue.component(SmallImgBox.name, SmallImgBox);
Vue.use(BaseDialog, vueobj); //注册全局的dialog 怎么用看代码
//输入框限制

Vue.prototype.$limit = {
  i12d1: /^\d{1,12}(\.(\d)?)?$/,
  i12d2: /^\d{1,12}(\.(\d{1,2})?)?$/,
  i12d3: /^\d{1,12}(\.(\d{1,3})?)?$/,
  i12d4: /^\d{1,12}(\.(\d{1,4})?)?$/,
  i14d6: /^\d{1,4}(\.(\d{1,6})?)?$/,
  i: /^\d+$/,
  i2: /^\d{1,2}$/,
  i3: /^\d{1,3}$/,
  i4: /^\d{1,4}$/,
  i5: /^\d{1,5}$/,
  i6: /^\d{1,6}$/,
  i7: /^\d{1,7}$/,
  i8: /^\d{1,8}$/,
  i9: /^\d{1,9}$/,
  i10: /^\d{1,10}$/,
  //不准许输入0开头的整数
  ii2: /^[1-9](\d{1,1})?$/,
  ii3: /^[1-9](\d{1,2})?/,
  ii4: /^[1-9](\d{1,3})?$/,
  ii5: /^[1-9](\d{1,4})?$/,
  ii6: /^[1-9](\d{1,5})?$/,
  ii7: /^[1-9](\d{1,6})?$/,
  ii8: /^[1-9](\d{1,7})?$/,
  ii9: /^[1-9](\d{1,8})?$/,
  ii10: /^[1-9](\d{1,9})?$/
};

Vue.prototype.$$copyText = function () {
  var copyResult;
  return function $$copyText(text) {
    copyResult = copyText(text);

    if (copyResult) {
      this.$message.success('复制成功');
    } else {
      this.$message.success('复制失败');
    }
  };
}(); //删除菜单那里的tab栏目


Vue.prototype.$$removeCacheTab = function (name) {
  this.$store.commit("app/".concat(SPLICE_CACHE_TABLE), name);
}; //删除当前菜单栏 进入下一个菜单


Vue.prototype.$$removePush = function () {
  var _this$$router;

  this.$$removeCacheTab(this.$route.name);

  (_this$$router = this.$router).push.apply(_this$$router, arguments);
}; //删除当前菜单栏 返回上一级菜单


Vue.prototype.$$removeBack = function () {
  this.$$removeCacheTab(this.$route.name);
  this.$router.back();
};