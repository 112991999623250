import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _ from 'lodash';
import { jsbarcodeGet } from '@/core/provide';
import setting from '@/settings';
import printJs from 'print-js';
import store from '@/store';
/**
 * TODO 获取菜单路由
 * @param {Array} menus - 菜单列表
 * @param {Route} route - vueRoute 单个路由对象
 * 如果当前的路由不在菜单上面的话  寻着他跟路由挂钩的菜单
 */

export var getMenuRoute = function getMenuRoute(menus, route) {
  return _.find(menus, {
    uri: route.meta.routeParent || route.name
  });
};
export var getParentRouteName = function getParentRouteName(route) {
  return route.meta.routeParent;
};
/**
 * TODO 盘点打印
 */

export var printInventoryHtml = function () {
  var defaultHead = [{
    name: '序号',
    key: '序号'
  }, {
    name: '图片',
    key: '图片'
  }, {
    name: '货位号',
    key: '货位号'
  }, {
    name: '商品编码',
    key: '商品编码'
  }, {
    name: '商品名称',
    key: '商品名称'
  }, {
    name: '可用库存',
    key: '可用库存'
  }, {
    name: '实盘数量',
    key: '实盘数量'
  }]; //默认渲染的tr

  var defaultTrRender = function defaultTrRender(_ref, index) {
    var container_no = _ref.container_no,
        goods_sn = _ref.goods_sn,
        goods_name = _ref.goods_name,
        available_stock = _ref.available_stock,
        inventory_num = _ref.inventory_num,
        goods_img = _ref.goods_img,
        attr_value = _ref.attr_value;
    return "\n            <tr>\n                <td align=\"center\">".concat(index + 1, "</td>\n                <td align=\"center\">\n                    <img width=\"60\" height=\"60\" src=\"").concat(store.state.app.imgDomain).concat(goods_img, "\"/>\n                </td>\n                <td align=\"center\">").concat(container_no, "</td>\n                <td align=\"center\">").concat(goods_sn, "</td>\n                <td align=\"center\">\n                    <div>").concat(goods_name, "</div>\n                    <div>").concat(attr_value, "</div>\n                </td>\n                <td align=\"center\">").concat(available_stock, "</td>\n                <td align=\"center\">").concat(inventory_num || '', "</td>\n            </tr>\n        ");
  };

  var createTmp = function createTmp(options) {
    var _options$head = options.head,
        head = _options$head === void 0 ? defaultHead : _options$head,
        base64 = options.base64,
        list = options.list,
        createUser = options.createUser,
        createTime = options.createTime,
        _options$trRender = options.trRender,
        trRender = _options$trRender === void 0 ? defaultTrRender : _options$trRender;
    return "\n            <div id=\"box\">\n                <div style=\"height: 10px;\"></div>\n                <table style=\"width: 100%;\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\">\n                    <tbody>\n                        <tr>\n                            <td align=\"left\">\n                                <img src=\"".concat(base64, "\" alt=\"\">\n                            </td>\n                            <td align=\"right\">\n                                <p>\u521B\u5EFA\u4EBA\uFF1A").concat(createUser, "</p>\n                                <p>\u521B\u5EFA\u65F6\u95F4\uFF1A").concat(createTime, "</p>\n                            </td>\n                        </tr>\n                    </tbody>\n                </table>\n                <div style=\"height: 20px;\"></div>\n                <table style=\"width: 100%; border-color: #EBEEF5; line-height: 30px;\" border=\"1\" cellspacing=\"0\" cellpadding=\"0\">\n                    <thead>\n                        <tr>\n                            ").concat(head.map(function (_ref2) {
      var name = _ref2.name;
      return "<th>".concat(name, "</th>");
    }).join(''), "\n                        </tr>\n                    </thead>\n                    <tbody>\n                        ").concat(list.map(trRender).join(''), "\n                    </tbody>\n                </table>\n            </div>\n        ");
  };

  return function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    jsbarcodeGet(options.code).then(function (base64) {
      printHtml(createTmp(_objectSpread(_objectSpread({}, options), {}, {
        base64: base64
      })), options.h2 || '盘点单');
    });
  };
}();
export var printHtml = function printHtml(str, title) {
  var elId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'box';
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var div = document.createElement('div');
  div.style.display = 'none';
  div.innerHTML = str;
  document.body.appendChild(div);
  console.log(str);
  printJs(_objectSpread({
    documentTitle: setting.title,
    printable: elId,
    type: 'html',
    header: title ? "\n            <h2 style=\"text-align: center; font-size: 40px;\">".concat(title, "</h2>\n        ") : undefined
  }, options));
  document.body.removeChild(div);
};