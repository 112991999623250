import "core-js/modules/es6.function.name";
//
export default {
  props: {
    list: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    titleMenus: function titleMenus(item) {
      var _this = this;

      var h = this.$createElement;
      return h("div", {
        "class": "nav-menus-item",
        "key": item.id
      }, [h("h4", {
        "class": "font-size-16 line-20 m-b-10"
      }, [h("span", {
        "on": {
          "click": function click() {
            return _this.$emit('goRoute', item.uri);
          }
        }
      }, [item.name])]), item.children.map(function (item) {
        return _this.getMenus(item);
      })]);
    },
    getMenus: function getMenus(item) {
      if (item.children) {
        return this.titleMenus(item);
      } else {
        return this.routeLink(item);
      }
    },
    routeLink: function routeLink(item) {
      var _this2 = this;

      var h = this.$createElement;
      return h("div", {
        "key": item.id,
        "class": "pointer font-size-14 m-r-20",
        "on": {
          "click": function click() {
            return _this2.$emit('goRoute', item.uri);
          }
        }
      }, [item.name]);
    }
  },
  render: function render() {
    var _this3 = this;

    var h = arguments[0];
    return h("div", {
      "class": "nav-menus-list flex"
    }, [this.list.map(function (item) {
      return _this3.getMenus(item);
    })]);
  }
};