import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export default {
  name: 'sa-dialog',
  data: function data() {
    return {
      visible: true
    };
  },
  render: function render() {
    var _this = this;

    var h = arguments[0];
    return h("el-dialog", {
      "attrs": {
        "visible": this.visible
      },
      "props": _objectSpread({}, _objectSpread({
        width: '600px',
        'close-on-click-modal': false,
        'custom-class': 'dialog-p20'
      }, this.props)),
      "on": _objectSpread({}, {
        'update:visible': function updateVisible(val) {
          return _this.visible = val;
        },
        'closed': this.remove
      })
    }, [h("div", {
      "slot": "title",
      "class": "el-dialog__title"
    }, [this.title]), this.content, h("div", {
      "slot": "footer",
      "class": "dialog-footer text-center"
    }, [this.btns.map(function (item, index) {
      return h("el-button", {
        "attrs": {
          "type": item.type === undefined ? 'primary' : item.type
        },
        "key": index,
        "on": {
          "click": function click() {
            return _this.handelClick(item);
          }
        },
        "class": item.class || ''
      }, [item.label]);
    })])]);
  }
};