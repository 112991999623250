import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SmallImgBox',
  props: {
    url: String,
    //需要查看大图的时候才有的
    index: {
      type: Number,
      default: 0
    },
    //查看大图时候的数组
    images: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    //是否可以删除
    remove: {
      type: Boolean,
      default: true
    },
    //是否可以放大
    zoom: {
      type: Boolean,
      default: true
    },
    hasDomain: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    look: function look() {
      $.pictureViewer({
        images: this.imageList,
        //需要查看的图片，数据类型为数组
        initImageIndex: this.index + 1 //初始查看第几张图片，默认1

      });
    }
  },
  computed: {
    imageList: function imageList() {
      var _this = this;

      return (this.images.length ? this.images : [this.url]).map(function (url) {
        return "".concat(_this.domainurl).concat(url);
      });
    },
    showMark: function showMark() {
      return this.remove || this.zoom;
    },
    domainurl: function domainurl() {
      return this.hasDomain ? '' : this.$store.state.app.imgDomain;
    }
  }
};