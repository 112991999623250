import "/var/www/erp/erpadmin/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/www/erp/erpadmin/node_modules/core-js/modules/es6.promise.js";
import "/var/www/erp/erpadmin/node_modules/core-js/modules/es6.object.assign.js";
import "/var/www/erp/erpadmin/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import '@/core/extend';
import '@/core/install';
import 'animate.css'; //animate.css   https://animate.style/

import '@/scss/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import '@/permission'; // permission control

Vue.config.productionTip = false; //添加域名

Vue.prototype.$STOCK_DOMAIN = process.env.VUE_APP_STOCK_DOMAIN; //仓库域名

Vue.prototype.$OMS_DOMAIN = process.env.VUE_APP_OMS_DOMAIN; //订单域名

Vue.prototype.$LMS_DOMAIN = process.env.VUE_APP_LMS_DOMAIN; //订单域名
//添加商品的域名

Vue.prototype.$PRODUCT_DOMAIN = process.env.VUE_APP_PRODUCT_DOMAIN;
Vue.prototype.$PRODUCT_LISTING_DOMAIN = process.env.VUE_APP_PRODUCT_LISTING_DOMAIN; //添加供应商的域名

Vue.prototype.$PMS_DOMAIN = process.env.VUE_APP_PMS_DOMAIN;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});