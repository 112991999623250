import { createRouteItem, layoutItem, createMeta } from "./unit.js";

var StockList = function StockList() {
  return import('@/views/stock/site');
}; //仓库列表


var StockManagementList = function StockManagementList() {
  return import('@/views/stock/management');
}; //仓位管理


var StockReceiptList = function StockReceiptList() {
  return import('@/views/stock/receipt');
}; //收货管理


var StockReceiptDetailList = function StockReceiptDetailList() {
  return import('@/views/stock/receipt/receipt-detail-index');
}; //收货管理-->收货明细


var StockPutShelfList = function StockPutShelfList() {
  return import('@/views/stock/put-shelf');
}; //上架管理


var StockPutShelfListMfl = function StockPutShelfListMfl() {
  return import('@/views/stock/put-shelf-mfl');
}; //面辅料上架单列表


var StockPutShelfDetailIndex = function StockPutShelfDetailIndex() {
  return import('@/views/stock/put-shelf/shelf-detail-index');
}; //上架管理-->上架明细


var StockPutShelfDetailIndexMfl = function StockPutShelfDetailIndexMfl() {
  return import('@/views/stock/put-shelf-mfl/shelf-detail-index');
}; //面辅料上架-->上架明细


var StockPrintLabel = function StockPrintLabel() {
  return import('@/views/stock/print-label');
}; //打印货品标签


var StockWarehouseList = function StockWarehouseList() {
  return import('@/views/stock/warehouse-list');
}; //仓库库存列表


var StockWarehouseListMfl = function StockWarehouseListMfl() {
  return import('@/views/stock/warehouse-list-mfl');
}; //仓库库存列表


var StockShelfLog = function StockShelfLog() {
  return import('@/views/stock/stock-shelf-log');
}; //货位库存变动日志


var StockLog = function StockLog() {
  return import('@/views/stock/stock-log');
}; //库存变动日志


var StockInventoryList = function StockInventoryList() {
  return import('@/views/stock/inventory-list');
}; //盘点


var StockInventorying = function StockInventorying() {
  return import('@/views/stock/inventorying');
}; //盘点明细


var StockInventoryDetail = function StockInventoryDetail() {
  return import('@/views/stock/inventory-detail');
}; //盘亏盘盈明细表


var StockWarehouseMove = function StockWarehouseMove() {
  return import('@/views/stock/warehouse-move');
}; //库存移动


var StockWarehouseMoveExc = function StockWarehouseMoveExc() {
  return import('@/views/stock/warehouse-move-exc');
}; //执行库存移动


var StockOutStock = function StockOutStock() {
  return import('@/views/stock/out-stock');
}; //出库管理


var StockOutStockDetail = function StockOutStockDetail() {
  return import('@/views/stock/out-stock-detail');
}; //出库单详情


var StockOutStockReview = function StockOutStockReview() {
  return import('@/views/stock/out-stock-review');
}; //出库复核


var StockOutStockErrorList = function StockOutStockErrorList() {
  return import('@/views/stock/out-stock-error-list');
}; //出库单异常列表


var StockPickList = function StockPickList() {
  return import('@/views/stock/pick-list');
}; //拣货单列表


var StockPickDetail = function StockPickDetail() {
  return import('@/views/stock/pick-detail');
}; //拣货单明细


var StockSecondPick = function StockSecondPick() {
  return import('@/views/stock/pick-second-pick');
}; //二次拣货


var StockSinglePick = function StockSinglePick() {
  return import('@/views/stock/pick-single-pick');
}; //二次拣货


var StockOutStockReturn = function StockOutStockReturn() {
  return import('@/views/stock/out-stock-return');
}; //出库单退单入库


var StockAccOutbound = function StockAccOutbound() {
  return import('@/views/stock/acc-outbound');
}; //面辅料出库列表


var StockAccOutboundCreate = function StockAccOutboundCreate() {
  return import('@/views/stock/acc-outbound/create');
}; //面辅料出库列表


var StockAccOutboundReturnList = function StockAccOutboundReturnList() {
  return import('@/views/stock/acc-outbound/return-list');
}; //面辅料出库列表


var StockSignForReport = function StockSignForReport() {
  return import('@/views/stock/sign-for-report');
}; //出库单退单入库


var StockDoorQcList = function StockDoorQcList() {
  return import('@/views/stock/door-qc');
}; //上门质检


var StockDoorQcCheck = function StockDoorQcCheck() {
  return import('@/views/stock/door-qc/check');
}; //上门质检


var StockQcList = function StockQcList() {
  return import('@/views/stock/qc-list');
}; //QC质检 stock-qc-in


var StockQcIn = function StockQcIn() {
  return import('@/views/stock/qc-in');
}; //质检明细 stock-qc-in


var StockQcImprove = function StockQcImprove() {
  return import('@/views/stock/qc-improve');
}; //批量异常改善单列表


var StockWarehouseArea = function StockWarehouseArea() {
  return import('@/views/stock/warehouse-area');
}; //货区管理


var StockOutPackage = function StockOutPackage() {
  return import('@/views/stock/out-package');
}; //出库包装


var StockShelfAllot = function StockShelfAllot() {
  return import('@/views/stock/shelf-allot');
}; //货位分配


var StockShelfRecycle = function StockShelfRecycle() {
  return import('@/views/stock/shelf-recycle');
}; //货位回收


var StockSupplySku = function StockSupplySku() {
  return import('@/views/stock/supply-sku');
}; //补货管理


var StockSupplyDetail = function StockSupplyDetail() {
  return import('@/views/stock/supply-detail');
}; //补货任务明细


var StockQIBadProcess = function StockQIBadProcess() {
  return import('@/views/stock/qi-bad-process');
}; //不良品处理列表


var InvoicingList = function InvoicingList() {
  return import('@/views/stock/invoicing-list');
}; //进销存列表


var WarehouseTodayReport = function WarehouseTodayReport() {
  return import('@/views/stock/warehouse-today-report');
}; //仓库日报看板


var StockReport = function StockReport() {
  return import('@/views/stock/stock-report');
}; //库存报表


var CategoryStockReport = function CategoryStockReport() {
  return import('@/views/stock/category-stock-report');
}; //分类库存报表


var InWarehouseQc = function InWarehouseQc() {
  return import('@/views/stock/in-warehouse-qc');
}; //在仓质检


var BadGoodsApplyScrap = function BadGoodsApplyScrap() {
  return import('@/views/pms/purchase-bad-process/scrap-list');
}; //不良品申请报废列表


var QcBillingStandard = function QcBillingStandard() {
  return import('@/views/stock/qc-billing-standard');
}; //问题类型计费标准
//仓库相关


export default layoutItem('/stock', [//仓库列表
createRouteItem('site-list', 'stock-site-list', StockList, createMeta('仓库列表')), //仓位管理
createRouteItem('management-list', 'stock-management-list', StockManagementList, createMeta('仓位管理')), //收货管理
createRouteItem('receipt-list', 'stock-receipt-list', StockReceiptList, createMeta('收货管理')), //收货管理-->收货明细
createRouteItem('receipt-detail-list', 'stock-receipt-detail-list', StockReceiptDetailList, createMeta('收货操作明细', 'stock-receipt-list')), //上架管理
createRouteItem('stock-put-shelf-list', 'stock-put-shelf-list', StockPutShelfList, createMeta('上架管理')), //面辅料上架
createRouteItem('stock-put-shelf-list-mfl', 'stock-put-shelf-list-mfl', StockPutShelfListMfl, createMeta('面辅料上架')), //上架管理-->上架明细
createRouteItem('stock-put-shelf-detail-index', 'stock-put-shelf-detail-index', StockPutShelfDetailIndex, createMeta('上架操作', 'stock-put-shelf-list')),, //面辅料上架-上架明细
createRouteItem('stock-put-shelf-detail-index-mfl', 'stock-put-shelf-detail-index-mfl', StockPutShelfDetailIndexMfl, createMeta('面辅料上架操作', 'stock-put-shelf-list-mfl')), //入库管理-打印货品标签
createRouteItem('stock-print-label', 'stock-print-label', StockPrintLabel, createMeta('打印货品标签')), //仓库库存列表  产品库存明细  货位库存明细
createRouteItem('warehouse-list', 'stock-warehouse-list', StockWarehouseList, createMeta('仓库库存列表')), //面辅料库存列表
createRouteItem('warehouse-list-mfl', 'stock-warehouse-list-mfl', StockWarehouseListMfl, createMeta('面辅料库存列表')), //货位库存变动日志
createRouteItem('shelf-log', 'stock-shelf-log', StockShelfLog, createMeta('货位库存变动日志', 'stock-warehouse-list')), //库存变动日志
createRouteItem('stock-log', 'stock-log', StockLog, createMeta('库存变动日志', 'stock-warehouse-list')), //盘点列表
createRouteItem('stock-inventory-list', 'stock-inventory-list', StockInventoryList, createMeta('盘点')), //盘点明细
createRouteItem('stock-inventorying', 'stock-inventorying', StockInventorying, createMeta('盘点明细', 'stock-inventory-list')), //盘点差异表
createRouteItem('stock-inventory-detail', 'stock-inventory-detail', StockInventoryDetail, createMeta('盘点差异表')), //库存移动
createRouteItem('stock-warehouse-move', 'stock-warehouse-move', StockWarehouseMove, createMeta('库存移动')), //执行库存移动
createRouteItem('stock-warehouse-move-exc', 'stock-warehouse-move-exc', StockWarehouseMoveExc, createMeta('执行库存移动', 'stock-warehouse-move')), // 出库管理  开始
//出库管理
createRouteItem('stock-out-stock', 'stock-out-stock', StockOutStock, createMeta('出库管理')), createRouteItem('stock-out-stock-detail', 'stock-out-stock-detail', StockOutStockDetail, createMeta('出库单明细', 'stock-out-stock')), //出库复核
createRouteItem('stock-out-stock-review', 'stock-out-stock-review', StockOutStockReview, createMeta('出库复核', 'stock-out-stock')), //出库单异常列表
createRouteItem('out-stock-error-list', 'out-stock-error-list', StockOutStockErrorList, createMeta('出库单异常列表')), //拣货单列表
createRouteItem('stock-pick-list', 'stock-pick-list', StockPickList, createMeta('拣货单列表')), //拣货单明细
createRouteItem('stock-pick-detail', 'stock-pick-detail', StockPickDetail, createMeta('拣货单明细', 'stock-pick-list')), //二次分拣-一单多件
createRouteItem('stock-second-pick', 'stock-second-pick', StockSecondPick, createMeta('二次分拣(多)', 'stock-pick-list')), //二次分拣-一单一件/两件
createRouteItem('stock-single-pick', 'stock-single-pick', StockSinglePick, createMeta('二次分拣(少)', 'stock-pick-list')), //退单入库
createRouteItem('stock-out-stock-return', 'stock-out-stock-return', StockOutStockReturn, createMeta('出库单退单入库', 'stock-receipt-list')), //面辅料出库单列表
createRouteItem('stock-acc-outbound', 'stock-acc-outbound', StockAccOutbound, createMeta('面辅料出库单列表')), //面辅料出库单列表
createRouteItem('stock-acc-outbound-create', 'stock-acc-outbound-create', StockAccOutboundCreate, createMeta('新增面辅料出库单', 'stock-acc-outbound')), //面辅料出库退货单列表
createRouteItem('stock-acc-outbound-return', 'stock-acc-outbound-return', StockAccOutboundReturnList, createMeta('面辅料出库退货单列表')), //签收报表
createRouteItem('stock-sign-for-report', 'stock-sign-for-report', StockSignForReport, createMeta('签收报表')), // 出库管理  结束
//上门质检
createRouteItem('door-qc-list', 'stock-door-qc-list', StockDoorQcList, createMeta('上门质检')), //上门质检操作
createRouteItem('door-qc-check', 'stock-door-qc-check', StockDoorQcCheck, createMeta('上门质检操作', 'stock-door-qc-list')), //QC质检
createRouteItem('qc-list', 'stock-qc-list', StockQcList, createMeta('QC质检')), //质检明细
createRouteItem('qc-in', 'stock-qc-in', StockQcIn, createMeta('开始质检操作', 'stock-qc-list')), //批量异常改善单列表
createRouteItem('qc-improve', 'stock-qc-improve', StockQcImprove, createMeta('批量异常改善单列表', 'stock-qc-improve')), //货区管理
createRouteItem('warehouse-area', 'stock-warehouse-area', StockWarehouseArea, createMeta('货区管理')), //出库包装
createRouteItem('out-package', 'stock-out-package', StockOutPackage, createMeta('出库包装')), //货位分配
createRouteItem('shelf-allot', 'stock-shelf-allot', StockShelfAllot, createMeta('货位分配')), //货位回收
createRouteItem('shelf-recycle', 'stock-shelf-recycle', StockShelfRecycle, createMeta('货位回收')), //补货管理
createRouteItem('supply-sku', 'stock-supply-sku', StockSupplySku, createMeta('补货管理')), //出库包装
createRouteItem('supply-detail', 'stock-supply-detail', StockSupplyDetail, createMeta('补货任务明细', 'stock-supply-sku')), // 质检
// 不良品处理列表
createRouteItem('bad-process-list', 'stock-qi-bad-process', StockQIBadProcess, createMeta('不良品处理列表')), //进销存列表
createRouteItem('invoicing-list', 'invoicing-list', InvoicingList, createMeta('进销存列表')), //仓库日报看板
createRouteItem('warehouse-today-report', 'warehouse-today-report', WarehouseTodayReport, createMeta('仓库日报看板')), //库存报表
createRouteItem('stock-report', 'stock-report', StockReport, createMeta('库存报表')), //分类库存报表
createRouteItem('category-stock-report', 'category-stock-report', CategoryStockReport, createMeta('类目库存报表')), //在仓质检
createRouteItem('in-warehouse-qc', 'in-warehouse-qc', InWarehouseQc, createMeta('在仓质检')), // 不良品申请作废列表
createRouteItem('apply-scrap-list', 'apply-scrap-list', BadGoodsApplyScrap, createMeta('不良品申请作废列表')), // 不良品申请作废列表
createRouteItem('qc-billing-standard', 'qc-billing-standard', QcBillingStandard, createMeta('问题类型计费标准'))]);