var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          style: "width:" + _vm.width + "px",
          attrs: {
            clearable: "",
            filterable: "",
            remote: "",
            size: "small",
            placeholder: _vm.placeholder || "请输入关键词",
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading,
          },
          on: { change: _vm.brandChange },
          model: {
            value: _vm.brand,
            callback: function ($$v) {
              _vm.brand = $$v
            },
            expression: "brand",
          },
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.brandData, function (item, index) {
      return _c("el-option", {
        key: index,
        attrs: { label: item.name_zh, value: item.brand_code },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }