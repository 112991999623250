var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "base-link primary-color primary-hover",
      attrs: {
        to: _vm.disabled
          ? "/disabled" /*随意值都行，disabled只是为了容易看懂*/
          : _vm.to,
        event: _vm.disabled ? "" : "click",
        disabled: _vm.disabled,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }