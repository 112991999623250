import { createRouteItem, layoutItem, createMeta } from "./unit.js";

var PMSSupplierList = function PMSSupplierList() {
  return import(
  /* webpackChunkName: 'pms-supplier-list' */
  '@/views/pms/supplier-list');
}; //供应商列表


var PMSSupplierCreate = function PMSSupplierCreate() {
  return import(
  /* webpackChunkName: 'pms-supplier-create' */
  '@/views/pms/supplier-create');
}; //添加供应商


var PMSSupplyManage = function PMSSupplyManage() {
  return import(
  /* webpackChunkName: 'pms-supply-manage' */
  '@/views/pms/supply-manage');
}; //供货管理列表


var PMSSkuSupplyManage = function PMSSkuSupplyManage() {
  return import(
  /* webpackChunkName: 'pms-sku-supply-manage' */
  '@/views/pms/sku-supply-manage');
}; //SKU供货关系待处理


var PMSSupplyLog = function PMSSupplyLog() {
  return import(
  /* webpackChunkName: 'pms-supply-log' */
  '@/views/pms/supply-log');
}; //日志


var PMSSupplyCreate = function PMSSupplyCreate() {
  return import(
  /* webpackChunkName: 'pms-supply-create' */
  '@/views/pms/supply-create');
}; //新增供货关系


var PMSPurchaseDemand = function PMSPurchaseDemand() {
  return import(
  /* webpackChunkName: 'pms-purchase-demand' */
  '@/views/pms/purchase-demand');
}; //采购需求


var PMSPurchaseOrder = function PMSPurchaseOrder() {
  return import(
  /* webpackChunkName: 'pms-purchase-order' */
  '@/views/pms/purchase-order');
}; //采购订单


var PMSPurchaseCreate = function PMSPurchaseCreate() {
  return import(
  /* webpackChunkName: 'pms-purchase-create' */
  '@/views/pms/purchase-create');
}; //添加采购订单


var PMSPurchaseView = function PMSPurchaseView() {
  return import(
  /* webpackChunkName: 'pms-purchase-create' */
  '@/views/pms/purchase-create/view');
}; //采购订单详情


var TerminationShipment = function TerminationShipment() {
  return import(
  /* webpackChunkName: 'pms-purchase-order-termination' */
  '@/views/pms/purchase-create/termination-shipment');
}; //终止确认SKU发货


var PMSPurchaseReturn = function PMSPurchaseReturn() {
  return import(
  /* webpackChunkName: 'pms-purchase-return' */
  '@/views/pms/purchase-return');
}; //添加回货信息


var PMSPurchaseDifference = function PMSPurchaseDifference() {
  return import(
  /* webpackChunkName: 'pms-purchase-difference' */
  '@/views/pms/purchase-difference');
}; //采购VS库存差异列表


var PMSPurchaseDifferencePrice = function PMSPurchaseDifferencePrice() {
  return import(
  /* webpackChunkName: 'pms-purchase-difference-price' */
  '@/views/pms/purchase-difference-price');
}; //采购价差异列表


var PMSPurchaseBadProcess = function PMSPurchaseBadProcess() {
  return import(
  /* webpackChunkName: 'pms-purchase-bad-process' */
  '@/views/pms/purchase-bad-process');
}; //采购不良品列表


var PMSPurchasePayment = function PMSPurchasePayment() {
  return import(
  /* webpackChunkName: 'pms-purchase-payment' */
  '@/views/pms/purchase-payment');
}; //采购付款


var PMSPurchaseDemandBatch = function PMSPurchaseDemandBatch() {
  return import(
  /* webpackChunkName: 'pms-purchase-demand' */
  '@/views/pms/purchase-demand/batch-index');
}; //批量生成采购单


var PMSPurchasePrePaymentApproval = function PMSPurchasePrePaymentApproval() {
  return import(
  /* webpackChunkName: 'pms-purchase-pre-purchase-order' */
  '@/views/pms/pre-purchase-order');
}; //采购订单预付款管理


var PMSPurchasePaymentApprovalDetail = function PMSPurchasePaymentApprovalDetail() {
  return import(
  /* webpackChunkName: 'pms-purchase-pre-purchase-order-detail' */
  '@/views/pms/pre-purchase-order/detail');
}; //采购订单预付款明细
//备货计划


var PMSStockingPlan = function PMSStockingPlan() {
  return import(
  /* webpackChunkName: 'pms-stocking-plan' */
  '@/views/pms/stocking-plan');
}; //备货计划管理


var PMSStockingCreate = function PMSStockingCreate() {
  return import(
  /* webpackChunkName: 'pms-stocking-create' */
  '@/views/pms/stocking-create');
}; //添加备货计划


var PMSStockingLog = function PMSStockingLog() {
  return import(
  /* webpackChunkName: 'pms-stocking-log' */
  '@/views/pms/stocking-log');
}; //备货日志


var PMSPurchaseOrderRefund = function PMSPurchaseOrderRefund() {
  return import(
  /* webpackChunkName: 'pms-purchase-order-refund' */
  '@/views/pms/purchase-refund');
}; //采购不良品退款


var PMSPurchaseOrderDeduction = function PMSPurchaseOrderDeduction() {
  return import(
  /* webpackChunkName: 'pms-purchase-order-deduction' */
  '@/views/pms/purchase-order-deduction');
}; //采购订单抵扣列表


var PMSDailyReport = function PMSDailyReport() {
  return import(
  /* webpackChunkName: 'pms-daily-report' */
  '@/views/pms/daily-report');
}; //采购日报


var PMSTarget = function PMSTarget() {
  return import(
  /* webpackChunkName: 'pms-target' */
  '@/views/pms/target');
}; //采购指标


var PMSTargetStat = function PMSTargetStat() {
  return import(
  /* webpackChunkName: 'pms-target' */
  '@/views/pms/target-stat');
}; //采购数据看板


var PMSlackResolutionTimeRecord = function PMSlackResolutionTimeRecord() {
  return import(
  /* webpackChunkName: 'pms-lack-resolution-time-record' */
  '@/views/pms/lack-resolution-time-record');
}; //缺货解决时效记录


var PMSSkcBargain = function PMSSkcBargain() {
  return import(
  /* webpackChunkName: 'pms-skc-bargain' */
  '@/views/pms/skc-bargain');
}; //SKC议价


var PMSDyeLotChromatism = function PMSDyeLotChromatism() {
  return import(
  /* webpackChunkName: 'pms-dye-lot-chromatism' */
  '@/views/pms/dye-lot-chromatism');
}; //缸差订单列表


var PMSSkcConvertMaking = function PMSSkcConvertMaking() {
  return import(
  /* webpackChunkName: 'pms-skc-convert-making' */
  '@/views/pms/skc-convert-making');
};

var PMSLackFollow = function PMSLackFollow() {
  return import(
  /* webpackChunkName: 'pms-lack-follow' */
  '@/views/pms/lack-follow');
}; //采购缺货跟单
//供应商


export default layoutItem('/pms', [//供应商列表
createRouteItem('supplier-list', 'pms-supplier-list', PMSSupplierList, createMeta('供应商列表')), //添加供应商 编辑供应商
createRouteItem('supplier-create', 'pms-supplier-create', PMSSupplierCreate, createMeta('添加供应商', 'pms-supplier-list')), //供货管理列表
createRouteItem('supply-manage', 'pms-supply-manage', PMSSupplyManage, createMeta('供货管理列表')), //SKU供货关系待处理
createRouteItem('sku-supply-manage', 'pms-sku-supply-manage', PMSSkuSupplyManage, createMeta('SKU供货关系待处理')), //供货管理列表 - 日志
createRouteItem('supply-log', 'pms-supply-log', PMSSupplyLog, createMeta('日志', 'pms-supply-manage')), //新增供货关系
createRouteItem('supply-create', 'pms-supply-create', PMSSupplyCreate, createMeta('新增供货关系', 'pms-supply-manage')), //采购需求
createRouteItem('purchase-demand', 'pms-purchase-demand', PMSPurchaseDemand, createMeta('采购需求')), //采购需求
createRouteItem('purchase-demand-batch', 'pms-purchase-demand-batch', PMSPurchaseDemandBatch, createMeta('批量生成采购单', 'pms-purchase-demand')), //采购订单
createRouteItem('purchase-order', 'pms-purchase-order', PMSPurchaseOrder, createMeta('采购订单')), //添加采购订单
createRouteItem('purchase-create', 'pms-purchase-create', PMSPurchaseCreate, createMeta('添加采购订单', 'pms-purchase-order')), //采购订单详情
createRouteItem('purchase-view', 'pms-purchase-view', PMSPurchaseView, createMeta('采购订单详情', 'pms-purchase-order')), //终止/确认SKU发货
createRouteItem('purchase-termination-shipment', 'purchase-termination-shipment', TerminationShipment, createMeta('终止/确认SKU发货', 'pms-purchase-order')), //采购订单详情
createRouteItem('purchase-return', 'pms-purchase-return', PMSPurchaseReturn, createMeta('添加发货信息', 'pms-purchase-order')), //采购VS库存差异列表
createRouteItem('purchase-difference', 'pms-purchase-difference', PMSPurchaseDifference, createMeta('采购VS库存差异列表')), // 采购价差异列表
createRouteItem('purchase-difference-price', 'pms-purchase-difference-price', PMSPurchaseDifferencePrice, createMeta('采购价差异列表')), //采购不良品列表
createRouteItem('purchase-bad-process', 'pms-purchase-bad-process', PMSPurchaseBadProcess, createMeta('采购不良品列表')), //采购付款
createRouteItem('purchase-payment', 'pms-purchase-payment', PMSPurchasePayment, createMeta('采购付款')), //备货计划管理
createRouteItem('stocking-plan', 'pms-stocking-plan', PMSStockingPlan, createMeta('备货计划管理')), //添加备货计划
createRouteItem('stocking-create', 'pms-stocking-create', PMSStockingCreate, createMeta('添加备货计划', 'pms-stocking-plan')), //添加备货计划
createRouteItem('stocking-log', 'pms-stocking-log', PMSStockingLog, createMeta('备货日志', 'pms-stocking-plan')), //采购订单预付款管理
createRouteItem('pre-purchase-order', 'pre-purchase-order', PMSPurchasePrePaymentApproval, createMeta('采购订单预付款管理')), //采购订单预付款明细
createRouteItem('pre-purchase-order-detail', 'pre-purchase-order-detail', PMSPurchasePaymentApprovalDetail, createMeta('采购订单预付款明细', 'pre-purchase-order')), // 采购不良品退款
createRouteItem('purchase-order-refund', 'purchase-order-refund', PMSPurchaseOrderRefund, createMeta('采购订单退款')), // 采购订单抵扣列表
createRouteItem('purchase-order-deduction', 'purchase-order-deduction', PMSPurchaseOrderDeduction, createMeta('采购订单抵扣列表')), // 采购日报
createRouteItem('daily-report', 'daily-report', PMSDailyReport, createMeta('采购日报')), //采购指标
createRouteItem('target', 'pms-target', PMSTarget, createMeta('采购指标')), //采购数据看板
createRouteItem('target-stat', 'pms-target-stat', PMSTargetStat, createMeta('采购数据看板')), //缺货解决时效记录
createRouteItem('lack-resolution-time-record', 'pms-lack-resolution-time-record', PMSlackResolutionTimeRecord, createMeta('缺货解决响应时效')), //SKC议价
createRouteItem('skc-bargain', 'pms-skc-bargain', PMSSkcBargain, createMeta('SKC议价列表')), // 缸差订单列表
createRouteItem('dye-lot-chromatism', 'dye-lot-chromatism', PMSDyeLotChromatism, createMeta('缸差订单列表')), //SKC转打版列表
createRouteItem('skc-convert-making', 'skc-convert-making', PMSSkcConvertMaking, createMeta('SKC转打版列表')), // 采购缺货跟单列表
createRouteItem('lack-follow', 'pms-lack-follow', PMSLackFollow, createMeta('采购缺货跟单列表'))]);