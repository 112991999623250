var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "query-item flex flex-aic" },
    [
      _vm.$scopedSlots.label
        ? _c("div", { staticClass: "query-label" }, [_vm._t("label")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.label
        ? _c("span", { staticClass: "query-label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }