import "core-js/modules/es6.array.find";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _queryList, { checkApiName, isSingle } from "./map.js";
export default {
  name: 'BaseSelectQuery',
  props: {
    value: {
      type: [Number, String, Array],
      default: undefined
    },
    apiName: {
      type: String,
      default: undefined
    },
    account: {
      type: String,
      default: undefined
    },
    defaultProps: {
      type: Object,
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: true
    },
    //一般用在编辑的时候
    initOptions: {
      type: Array,
      default: undefined
    },
    //转换options
    transforOptions: {
      type: Function,
      default: function _default(arr) {
        return arr;
      }
    },
    //编辑的时候搜索需要的name
    initName: {
      type: String,
      default: ''
    },
    //文本
    label: {
      type: [Number, String],
      default: undefined
    }
  },
  data: function data() {
    return {
      options: [],
      selectValue: this.value,
      loading: false,
      load: false,
      //有没有加载过
      single: isSingle(this.apiName) //是不是不用输入查询的

    };
  },
  watch: {
    value: function value(newval) {
      !_.isEqual(newval, this.selectValue) && (this.selectValue = newval);
    },
    selectValue: function selectValue(newval) {
      !_.isEqual(newval, this.value) && this.$emit('input', newval);
      this.updateLabel();
    },
    //这里记住  如果不是搜索的输入框  那就不要传这个 这个主要是针对编辑  如果编辑有id 不是搜索查询的话是肯定能带出来的
    initOptions: {
      immediate: true,
      handler: function handler(v1, v2) {
        if (v1) {
          this.options = v1;
        }
      }
    },
    //这里是 initOptions 的替换方案  用name反查出列表
    initName: {
      immediate: true,
      handler: function handler(v1, v2) {
        v1 && this.queryList(v1);
      }
    }
  },
  created: function created() {
    /**
     * TODO 如果 initOptions 有的话 证明是搜索 那样的话直接用 initOptions 就可以了 搜索的时候自然会带出别的来
     */
    if (!this.initOptions && !this.initName) {
      this.queryList(this.initName);
    }

    this.load = true;
    process.env.NODE_ENV === 'development' && checkApiName(this.apiName, this.initOptions);
  },
  methods: {
    queryList: function queryList(value) {
      var _this = this;

      if (this.load && this.single) return;

      if (this.apiName) {
        return _queryList(this.apiName, value, this.defaultProps).then(function (list) {
          _this.options = _this.transforOptions(list);
          _this.loading = false;

          _this.updateLabel();
        }).catch(function () {
          _this.loading = false;
        });
      }
    },
    sendData: function sendData() {
      this.$emit('selectFn', {
        value: this.selectValue,
        apiName: this.apiName
      });
    },
    selectItem: function selectItem() {
      return this.selectValue ? _.find(this.options, {
        value: this.selectValue
      }) : null;
    },
    updateLabel: function updateLabel() {
      this.$emit('update:label', this.selectLabel);
      this.$emit('update:item', this.selectItem());
    }
  },
  computed: {
    selectLabel: function selectLabel() {
      var item = this.selectItem();
      return item ? item.label : '';
    }
  }
};