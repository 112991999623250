var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseSelect",
    _vm._g(
      _vm._b(
        {
          attrs: {
            options: _vm.options,
            filterable: "",
            clearable: _vm.clearable,
            loading: _vm.loading,
            query: _vm.queryList,
            remote: !_vm.single,
            account: _vm.account,
          },
          on: { change: _vm.sendData },
          model: {
            value: _vm.selectValue,
            callback: function ($$v) {
              _vm.selectValue = $$v
            },
            expression: "selectValue",
          },
        },
        "BaseSelect",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }