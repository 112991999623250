var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseSelect", {
        attrs: {
          options: _vm.area_mixins_province_options,
          placeholder: "请选择省",
          filterable: "",
        },
        on: { change: _vm.$area_mixins_province_change },
        model: {
          value: _vm.area_mixins_province,
          callback: function ($$v) {
            _vm.area_mixins_province = $$v
          },
          expression: "area_mixins_province",
        },
      }),
      _vm._v(" "),
      _c("BaseSelect", {
        attrs: {
          options: _vm.area_mixins_city_options,
          placeholder: "请选择市",
          filterable: "",
        },
        on: { change: _vm.$area_mixins_city_change },
        model: {
          value: _vm.area_mixins_city,
          callback: function ($$v) {
            _vm.area_mixins_city = $$v
          },
          expression: "area_mixins_city",
        },
      }),
      _vm._v(" "),
      _c("BaseSelect", {
        attrs: {
          options: _vm.area_mixins_area_options,
          placeholder: "请选择县",
          filterable: "",
        },
        model: {
          value: _vm.area_mixins_area,
          callback: function ($$v) {
            _vm.area_mixins_area = $$v
          },
          expression: "area_mixins_area",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }