import "core-js/modules/es6.array.find";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";

/**
 * TODO 默认导出地址选择
 */
export default {
  data: function data() {
    return {
      area_mixins_list: [],
      area_mixins_province: '',
      //省下拉值
      area_mixins_city: '',
      //市下拉值
      area_mixins_area: '' //县下拉值

    };
  },
  created: function created() {
    var _this = this;

    import("./area.json").then(function (list) {
      _this.area_mixins_list = Array.from(list.default);
    });
  },
  methods: {
    //转换成下拉需要的格式
    $area_mixins_transfor_area: function $area_mixins_transfor_area(list) {
      return list.map(function (_ref) {
        var label = _ref.n,
            p = _ref.p;
        return {
          label: label,
          value: label,
          p: p
        };
      });
    },
    //根据层级获取下一级的下拉列表
    $area_mixins_deep_area: function $area_mixins_deep_area(list) {
      if (_.compact(list).length !== list.length || !list.length) return [];

      try {
        return this.$area_mixins_transfor_area(list.reduce(function (pre, n) {
          var item = _.find(pre, {
            n: n
          });

          return item ? item.s : pre;
        }, this.area_mixins_list));
      } catch (e) {
        console.log('根据层级获取下一级的下拉列表 e', e);
        return [];
      }
    },
    //省下拉事件
    $area_mixins_province_change: function $area_mixins_province_change() {
      this.$area_mixins_clean_value(1, 3);
    },
    //市下拉事件
    $area_mixins_city_change: function $area_mixins_city_change() {
      this.$area_mixins_clean_value(2, 3);
    },
    //清除下拉选中值
    $area_mixins_clean_value: function $area_mixins_clean_value(b, n) {
      var strs = ['area_mixins_province', 'area_mixins_city', 'area_mixins_area'];

      for (var i = b; i < n; i++) {
        this[strs[i]] = '';
      }
    },
    //本地搜索方法
    $area_mixins_filter_method: function $area_mixins_filter_method() {
      return [];
    }
  },
  computed: {
    //省下拉选项
    area_mixins_province_options: function area_mixins_province_options() {
      return this.$area_mixins_transfor_area(this.area_mixins_list);
    },
    //市下拉选项
    area_mixins_city_options: function area_mixins_city_options() {
      return this.$area_mixins_deep_area([this.area_mixins_province]);
    },
    //县下拉选项
    area_mixins_area_options: function area_mixins_area_options() {
      return this.$area_mixins_deep_area([this.area_mixins_province, this.area_mixins_city]);
    }
  }
};