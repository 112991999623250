//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import areaMixins from '@/plugins/city';
export default {
  props: {
    province: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: ''
    },
    area: {
      type: String,
      default: ''
    }
  },
  name: 'BaseArea',
  watch: {
    province: function province(v1) {
      this.area_mixins_province = v1;
    },
    city: function city(v1) {
      this.area_mixins_city = v1;
    },
    area: function area(v1) {
      this.area_mixins_area = v1;
    },
    area_mixins_province: function area_mixins_province(v1) {
      this.$emit('update:province', v1);
    },
    area_mixins_city: function area_mixins_city(v1) {
      this.$emit('update:city', v1);
    },
    area_mixins_area: function area_mixins_area(v1) {
      this.$emit('update:area', v1);
    }
  },
  mixins: [areaMixins],
  created: function created() {
    this.area_mixins_province = this.province;
    this.area_mixins_city = this.city;
    this.area_mixins_area = this.area;
  }
};