import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { get, post, uploadNew, lmsConfig } from '@/api';
import { cacheApi } from '@/core/provide';
/**
 * TODO 物流相关接口
 */
//获取租户的物流渠道

export var LogisticsTenantChannel = function LogisticsTenantChannel(params) {
  return get('/Logistics/TenantChannel', _objectSpread(_objectSpread({}, params), {}, {
    page_size: params.page_size ? params.page_size : 500
  }), lmsConfig({
    loading: false
  }));
}; //获取租户的物流渠道(含禁用)

export var LogisticsTenantChannelWithDisable = function LogisticsTenantChannelWithDisable(params) {
  return get('/Logistics/TenantChannel', _objectSpread(_objectSpread({}, params), {}, {
    status: [1, 2],
    page_size: params.page_size ? params.page_size : 500
  }), lmsConfig({
    loading: false
  }));
}; //获取物流服务商列表

export var LogisticsProvider = function LogisticsProvider(params) {
  return get('/Logistics/Provider', params, lmsConfig());
}; // 获取物流渠道列表

export var LogisticsChannel = function LogisticsChannel(params) {
  return get('/Logistics/Channel', params, lmsConfig());
}; // 创建租户的物流渠道

export var LogisticsCreateTenantChannel = function LogisticsCreateTenantChannel(params) {
  return post('/Logistics/CreateTenantChannel', params, lmsConfig());
}; // 更新租户的物流渠道

export var LogisticsUpdateTenantChannel = function LogisticsUpdateTenantChannel(params) {
  return post('/Logistics/UpdateTenantChannel', params, lmsConfig());
}; // 通过渠道编码获取渠道信息

export var LogisticsGetChannelByCode = function LogisticsGetChannelByCode(params) {
  return get('/Logistics/GetChannelByCode', params, lmsConfig());
}; //租户物流渠道列表

export var LogisticsTenantChannelList = function LogisticsTenantChannelList(params) {
  return get('/Logistics/TenantChannelList', params, lmsConfig());
}; //通过渠道编码获取租户渠道信息

export var LogisticsGetTenantChannelByCode = function LogisticsGetTenantChannelByCode(params) {
  return get('/Logistics/GetTenantChannelByCode', params, lmsConfig());
}; //新建物流服务商

export var LogisticsCreateProvider = function LogisticsCreateProvider(params) {
  return post('/Logistics/CreateProvider', params, lmsConfig());
}; //更新物流服务商

export var LogisticsUpdateProvider = function LogisticsUpdateProvider(params) {
  return post('/Logistics/UpdateProvider', params, lmsConfig());
}; //根据物流服务商编码获取物流服务商

export var LogisticsGetProviderByCode = function LogisticsGetProviderByCode(params) {
  return get('/Logistics/GetProviderByCode', params, lmsConfig());
}; //创建物流渠道

export var LogisticsCreateChannel = function LogisticsCreateChannel(params) {
  return post('/Logistics/CreateChannel', params, lmsConfig());
}; //更新物流渠道

export var LogisticsUpdateChannel = function LogisticsUpdateChannel(params) {
  return post('/Logistics/UpdateChannel', params, lmsConfig());
}; //搜索物流服务商

export var LogisticsSearchProvider = function LogisticsSearchProvider(params) {
  return get('/Logistics/SearchProvider', params, lmsConfig({
    loading: false
  }));
}; //搜索物流渠道

export var LogisticsSearchChannel = function LogisticsSearchChannel(params) {
  return get('/Logistics/SearchChannel', params, lmsConfig({
    loading: false
  }));
}; //物流配置

export var LogisticschannelConfig = cacheApi(function (params) {
  return get('/Logistics/channelConfig', params, lmsConfig());
}); //费用类型

export var feefee = cacheApi(function (params) {
  return get('/fee/fee', params, lmsConfig());
}); //费用管理列表

export var feelist = function feelist(params) {
  return post('/fee/list', params, lmsConfig());
}; //费用类型添加

export var feeadd = function feeadd(params) {
  return post('/fee/add', params, lmsConfig());
}; //费用管理编辑

export var feeedit = function feeedit(params) {
  return post('/fee/edit', params, lmsConfig());
}; //物流分区列表

export var LogisticsZonelist = function LogisticsZonelist(params) {
  return post('/LogisticsZone/list', params, lmsConfig());
}; //物流分区列表导入

export var LogisticsZoneimport = function LogisticsZoneimport(params) {
  return post('/LogisticsZone/import', params, lmsConfig());
}; //物流分区列表导入

export var LogisticsZoneexport = function LogisticsZoneexport(params) {
  return get('/LogisticsZone/export', params, lmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //物流渠道通邮国家列表

export var LogisticsCountryList = function LogisticsCountryList(params) {
  return get('/Logistics/ChannelCountry', params, lmsConfig());
}; //物流申报价值列表

export var LogisticsValueList = function LogisticsValueList(params) {
  return get('/declare-value', params, lmsConfig());
}; //物流申报价值导入

export var LogisticsValueImport = function LogisticsValueImport(params, file) {
  return uploadNew('/declare-value/import', params, file, lmsConfig());
}; //物流申报价值导出

export var LogisticsValueExport = function LogisticsValueExport(params) {
  return get('/declare-value/export', params, lmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //物流价格列表

export var LogisticsPricelist = function LogisticsPricelist(params) {
  return post('/LogisticsPrice/list', params, lmsConfig());
}; //物流价格列表导入

export var LogisticsPriceimport = function LogisticsPriceimport(params) {
  return post('/LogisticsPrice/import', params, lmsConfig());
}; //物流渠道模拟运费计算

export var LogisticscalcShippingFee = function LogisticscalcShippingFee(params) {
  return post('/Logistics/calcShippingFee', params, lmsConfig());
};