var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "router-tab" },
    [
      _c("el-scrollbar", [
        _c(
          "ul",
          { staticClass: "router-list" },
          _vm._l(_vm.cacheTab, function (item, index) {
            return _c(
              "li",
              {
                key: item.name,
                class: { active: _vm.isActive(item) },
                on: {
                  click: function ($event) {
                    return _vm.goPage(item)
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.getTabTitle(item)))]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.close(index, item)
                    },
                  },
                }),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }