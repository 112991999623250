import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.map";
import defaultSettings from '@/settings';
var title = defaultSettings.title || 'Vue Admin Template';
export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(pageTitle, " - ").concat(title);
  }

  return "".concat(title);
}
/**
 * TODO title 配置
 * 针对有几种状态公用一个路由又要区分title时候的配置
 * Map key 路由的name
 * Map value 可以是一个对象   里面有三种状态的title 或者也可以是一个函数  针对复杂情况
 */

var createTitle = function createTitle(create, edit, look) {
  return {
    create: create,
    edit: edit,
    look: look
  };
};

var create3Title = function create3Title(text) {
  return createTitle("\u65B0\u589E".concat(text), "\u7F16\u8F91".concat(text), "\u67E5\u770B".concat(text));
};

var titleMap = new Map([['order-distribution-view', //订单配货 配货   查看
createTitle('待配货订单', '编辑配货订单', '配货详情')], ['pms-supplier-create', //供应商 新增  编辑 查看
createTitle('新增供应商', '编辑供应商', '查看供应商')], ['pms-purchase-create', //供应商 新增  编辑 查看
createTitle('添加采购订单', '编辑采购订单', '查看采购订单')], ['order-customer-create', //客户信息 新增  编辑 查看
createTitle('新增客户信息', '编辑客户信息', '查看客户信息')], ['pms-supply-create', create3Title('供货关系')], ['product-create', create3Title('产品')], ['logistics-create-user-channel', create3Title('租户渠道')], ['pms-stocking-create', create3Title('备货计划')], ['product-model-library-create', create3Title('模特')], ['wash-protect-template-create', create3Title('洗护模板')], ['product-accessories-create', create3Title('面辅料')], ['stock-acc-outbound-create', create3Title('面辅料出库单')], ['order-internet-celebrity-create', create3Title('红人')], ['order-internet-celebrity-order-create', create3Title('红人订单')]]);
/**
 * TODO 任何路由只要是有编辑  查看的  统一用  look   create  edit
 * 默认是create   如果是不确定情况下的话就返回空   页面自身会用路由配置的title去显示的
 */

export var transTabTitle = function transTabTitle(route) {
  if (titleMap.has(route.name)) {
    var value = titleMap.get(route.name);

    if (typeof value === 'function') {
      return value(route.query);
    } else {
      return value[route.query.type || 'create'];
    }
  }
};