import "core-js/modules/es6.array.find";
import _defineProperty from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find-index";

var _mutations, _actions;

import { COMMAN_CONFIG, MENUS_ROLES, PUSH_CACHE_TAB, CACHE_TAB_CLICK, NEXT_REFRESH_NAME, SPLICE_CACHE_TABLE, RATES } from '@/store/types';
import { configcommonConfig, exchangeratelist } from '@/api/common';
import { getMenusRoute } from '@/api/user';
import { setCacheTab, getCacheTab } from '@/core/auth';
import { transTree } from '@/core/provide';
var maxTab = 10;
var state = {
  imgDomain: '',
  //图片路径
  menuTree: [],
  //菜单树
  menus: [],
  //菜单
  cacheTab: getCacheTab(),
  //缓存的tab

  /**
   * 当前正在进入的缓存页面  如果这个值有的话
   */
  isCacheClick: false,
  nextRefreshName: '',
  //下一个进入要刷新的路由name  默认不管进入任何页面  都是需要刷新的  只有点击菜单栏下面的tab才不用
  rates: [] //汇率

};
var mutations = (_mutations = {}, _defineProperty(_mutations, COMMAN_CONFIG, function (state, payload) {
  state.configLoad = true;
  state.imgDomain = payload.file_domain;
}), _defineProperty(_mutations, MENUS_ROLES, function (state, _ref) {
  var tree = _ref.tree,
      list = _ref.list;
  state.menuTree = tree;
  state.menus = list;
}), _defineProperty(_mutations, PUSH_CACHE_TAB, function (state, payload) {
  if (state.isCacheClick) return; //如果是缓存点击的话  就什么都不做 证明头部就有这个东西

  /**
   * TODO 找到下标
   * 如果有 tabName 属性 那么就是取 tabName比对  或者name 一样也算找到了
   * 如果找到了就把当前的删除 在把新加入的丢进去
   * 这里如果是正常的跳转的话到另外一个页面  都需要刷新页面  只有在列表里面点击的话就不需要
   */

  var index = _.findIndex(state.cacheTab, function (_ref2) {
    var name = _ref2.name,
        tabName = _ref2.meta.tabName;
    return tabName && tabName === payload.meta.tabName || name === payload.name;
  });

  if (~index) {
    state.cacheTab.splice(index, 1);
  }

  state.cacheTab.push(payload);

  while (state.cacheTab.length > maxTab) {
    state.cacheTab.shift();
  }

  setCacheTab(state.cacheTab);
}), _defineProperty(_mutations, SPLICE_CACHE_TABLE, function (state, payload) {
  state.cacheTab.splice(_.findIndex(state.cacheTab, {
    name: payload
  }), 1);
  setCacheTab(state.cacheTab);
}), _defineProperty(_mutations, CACHE_TAB_CLICK, function (state, payload) {
  state.isCacheClick = payload;
}), _defineProperty(_mutations, NEXT_REFRESH_NAME, function (state, payload) {
  state.nextRefreshName = payload;
}), _defineProperty(_mutations, RATES, function (state, payload) {
  state.rates = payload;
}), _mutations);
var actions = (_actions = {}, _defineProperty(_actions, COMMAN_CONFIG, function (_ref3) {
  var commit = _ref3.commit,
      state = _ref3.state;
  return configcommonConfig().then(function (_ref4) {
    var code = _ref4.code,
        data = _ref4.data;

    if (code === SUCCESSCODE) {
      commit(COMMAN_CONFIG, data);
    }
  });
}), _defineProperty(_actions, MENUS_ROLES, function (_ref5) {
  var commit = _ref5.commit;
  return getMenusRoute().then(function (_ref6) {
    var code = _ref6.code,
        data = _ref6.data,
        message = _ref6.message;

    if (code === SUCCESSCODE) {
      commit(MENUS_ROLES, transTree(data));
    }
  }).catch(console.log);
}), _defineProperty(_actions, RATES, function (_ref7) {
  var commit = _ref7.commit;
  exchangeratelist({
    status: 1,
    page: 1,
    page_size: 100
  }).then(function (_ref8) {
    var code = _ref8.code,
        data = _ref8.data,
        message = _ref8.message;

    if (code === SUCCESSCODE) {
      commit(RATES, data.list);
    }
  }).catch(console.log);
}), _actions);
var getters = {
  cny: function cny(state) {
    var cnyRete = _.find(state.rates, {
      currency_code: 'CNY'
    });

    return cnyRete ? parseFloat(cnyRete.rate) : 0.145;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};