import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
import Header from "./header";
import routerTab from "./router-tab";
import crumbs from "./crumbs";
import _ from 'lodash';
import { getMenuRoute, getParentRouteName } from '@/core/app';
import { NEXT_REFRESH_NAME, CACHE_TAB_CLICK } from '@/store/types';
export default {
  components: {
    Header: Header,
    crumbs: crumbs,
    routerTab: routerTab
  },
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      isRouterReload: true
    };
  },
  created: function created() {
    window.testroute = this;
  },
  watch: {
    $route: {
      handler: function handler(route, oldRoute) {
        var _this = this;

        /**
         * TODO 只有当前页面跟之前的路由页面一样的时候才要去刷新路由组件 不然是没有必要的  因为如果缓存 刷新不刷新无所谓
         * 不缓存这个新的url的话  他就会直接走正常的逻辑  也不会存在不缓存
         */
        if (route.name === oldRoute.name) {
          this.isRouterReload = false;
        }

        this.$nextTick(function () {
          _this.isRouterReload = true;

          _this.$store.commit("app/".concat(NEXT_REFRESH_NAME), '');

          _this.$store.commit("app/".concat(CACHE_TAB_CLICK), false);

          _this.$nextTick(function () {
            if (route.name !== _this.$refs.route.$options.name) {
              throw Error("\u5F53\u524D\u7EC4\u4EF6\u540D\u5B57\u8DDF\u8DEF\u7531\u7684name\u4E0D\u4E00\u81F4\uFF0C \u8BF7\u4FEE\u6539\u4E00\u81F4\uFF0C \u5F53\u524D\u8DEF\u7531url\u4E3A".concat(route.fullPath, ", \u8BF7\u5728\u7EC4\u4EF6\u91CC\u9762\u628Aname\u52A0\u4E0A"));
            }
          });
        });
      }
    }
  },
  computed: {
    crumbs: function crumbs() {
      var _this2 = this;

      var route = getMenuRoute(this.menus, this.$route); //如果能找到这个菜单  就证明有面包屑  不然就没有

      if (route) {
        var ids = route._ids;
        var parentName = getParentRouteName(this.$route);
        return _.compact(ids.map(function (id) {
          return _.find(_this2.menus, {
            id: id
          });
        }).concat(parentName ? this.$route : null));
      }

      return [];
    },
    menus: function menus() {
      return this.$store.state.app.menus;
    },
    app: function app() {
      return this.$store.state.app;
    },
    cashViews: function cashViews() {
      var _this3 = this;

      return this.app.cacheTab.map(function (_ref) {
        var name = _ref.name;
        return name;
      }).filter(function (name) {
        return name !== _this3.app.nextRefreshName;
      });
    },

    /**
     *
     * 有的组件有单例变量数据，被缓存有问题，比如修改商品组件src/views/product/create/index.vue
     * 一时也想不出清除keep-alive的方法，就只能先不缓存这些组件。
     */
    excludeCacheViews: function excludeCacheViews() {
      return this.app.cacheTab.filter(function (_ref2) {
        var meta = _ref2.meta;
        return meta && meta.cacheView === false;
      }).map(function (_ref3) {
        var name = _ref3.name;
        return name;
      });
    }
  },
  mounted: function mounted() {},
  methods: {
    reload: function reload(name) {
      var _this4 = this;

      var copyCashViews = _.cloneDeep(this.cashViews);

      var index = null;
      this.cashViews.forEach(function (row, i) {
        if (row == name) {
          index = i;
        }
      });

      if (index !== null) {
        this.cashViews.splice(index, 1);
      }

      this.isRouterReload = false;
      this.$nextTick(function () {
        _this4.cashViews = copyCashViews;
        _this4.isRouterReload = true;
      });
    }
  }
};