import { get, post, pmsConfig, uploadNew, lmsConfig, omsConfig } from '@/api';
/**
 * TODO 供应商相关接口
 */
//供应商-列表

export var supplierindex = function supplierindex(params) {
  return get('/v1/supplier/index', params, pmsConfig());
}; //供应商-启用/禁用

export var supplierresetstatus = function supplierresetstatus(params) {
  return post('/v1/supplier/reset-status', params, pmsConfig());
}; //供应商-新增

export var supplieradd = function supplieradd(params) {
  return post('/v1/supplier/add', params, pmsConfig());
}; //供应商-修改

export var supplieredit = function supplieredit(params) {
  return post('/v1/supplier/update', params, pmsConfig());
}; //供应商-查看

export var supplierview = function supplierview(params) {
  return get('/v1/supplier/view', params, pmsConfig());
}; //供应商-供应指定sku列表的供应商列表

export var availablesupplierlist = function availablesupplierlist(params) {
  return get('/v1/supplier/available-list', params, pmsConfig());
}; //供货关系-列表

export var supplyindex = function supplyindex(params) {
  return get('/v1/supply-relation/index', params, pmsConfig());
}; //供货关系-待处理列表

export var supplyindexunprocess = function supplyindexunprocess(params) {
  return get('/v1/supply-relation/un-process', params, pmsConfig());
}; //供货关系-添加

export var supplyadd = function supplyadd(params) {
  return post('/v1/supply-relation/add', params, pmsConfig());
}; //供货关系-更新

export var supplyupdate = function supplyupdate(params) {
  return post('/v1/supply-relation/update', params, pmsConfig());
}; //供货关系-启用/禁用

export var supplyresetstatus = function supplyresetstatus(params) {
  return post('/v1/supply-relation/reset-status', params, pmsConfig());
}; //供货关系-批量更新

export var supplybatchupdate = function supplybatchupdate(params) {
  return post('/v1/supply-relation/batch-update', params, pmsConfig());
}; //供货关系-日志

export var supplyviewtrack = function supplyviewtrack(params) {
  return get('/v1/supply-relation/view-track', params, pmsConfig());
}; //供货关系-查看供货关系

export var supplyview = function supplyview(params) {
  return get('/v1/supply-relation/view', params, pmsConfig());
}; //供货关系-批量新增供货关系

export var batchSupplyview = function batchSupplyview(params) {
  return get('/v1/supply-relation/view-batch-add', params, pmsConfig());
}; //供货关系-根据产品编码获取存在供货关系的供应商

export var supplygetsupplier = function supplygetsupplier(params) {
  return get('/v1/supply-relation/get-supplier', params, pmsConfig());
}; //货关系-根据产品编码（一个）+供应商编码获取供货关系

export var supplygetrow = function supplygetrow(params) {
  return get('/v1/supply-relation/get-row', params, pmsConfig());
}; //货关系-根据产品编码（多个）+供应商编码获取供货关系

export var supplygetrows = function supplygetrows(params) {
  return get('/v1/supply-relation/get-rows', params, pmsConfig());
}; //SKU供货关系待处理表-批量导出

export var supplyexport = function supplyexport(params) {
  return post('/v1/supply-relation/unprocess-export', params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //sku供货关系处理表-导入供货关系

export var supplyimport = function supplyimport(params, file) {
  return uploadNew('/v1/supply-relation/import', params, file, pmsConfig());
}; //采购需求-列表

export var purchasedemandindex = function purchasedemandindex(params) {
  return get('/v1/purchase-demand/index', params, pmsConfig());
}; //采购需求-创建订单

export var purchasedemandcreateorder = function purchasedemandcreateorder(params) {
  return post('/v1/purchase-demand/create-order', params, pmsConfig());
}; //采购需求-批量按SKC自动生成采购单

export var purchasedemandcreateorder2 = function purchasedemandcreateorder2(params) {
  return post('/v1/purchase-demand/create-order2', params, pmsConfig());
}; //采购需求-汇总列表

export var purchasedemandsummary = function purchasedemandsummary(params) {
  return get('/v1/purchase-demand/summary', params, pmsConfig());
}; //采购需求-导出汇总列表

export var purchaseDemandExportSummary = function purchaseDemandExportSummary(params) {
  return get('/v1/purchase-demand/export-summary', params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 暂不采购操作

export var purchasedemanddelayAction = function purchasedemanddelayAction(params) {
  return post('/v1/purchase-demand/delay', params, pmsConfig());
}; // 恢复采购操作

export var purchasedemandrestoreAction = function purchasedemandrestoreAction(params) {
  return post('/v1/purchase-demand/restore', params, pmsConfig());
}; // 暂不采购列表

export var purchasedemanddelayList = function purchasedemanddelayList(params) {
  return get('/v1/purchase-demand/delay-summary', params, pmsConfig());
}; //采购订单--列表

export var purchaseorderindex = function purchaseorderindex(params) {
  return get('/v1/purchase-order/index', params, pmsConfig());
}; //采购订单--编辑视图

export var purchaseorderview = function purchaseorderview(params) {
  return get('/v1/purchase-order/view', params, pmsConfig());
}; //采购订单--编辑保存

export var purchaseorderupdate = function purchaseorderupdate(params) {
  return post('/v1/purchase-order/update', params, pmsConfig());
}; //采购订单--编辑移除单个明细sku

export var purchaseorderremoveone = function purchaseorderremoveone(params) {
  return get('/v1/purchase-order/remove-one', params, pmsConfig());
}; //采购订单--删除订单

export var purchaseorderdelete = function purchaseorderdelete(params) {
  return post('/v1/purchase-order/delete', params, pmsConfig());
}; //采购订单--打印采购单

export var purchaseorderprint = function purchaseorderprint(params) {
  return get('/v1/purchase-order/print', params, pmsConfig());
}; //采购订单--审核页面

export var purchaseauditview = function purchaseauditview(params) {
  return get('/v1/purchase-order/audit-view', params, pmsConfig());
}; //采购订单--审核

export var purchaseorderaudit = function purchaseorderaudit(params) {
  return post('/v1/purchase-order/audit', params, pmsConfig());
}; //采购订单--请款

export var purchaseorderapplypayment = function purchaseorderapplypayment(params) {
  return post('/v1/purchase-order/apply-payment', params, pmsConfig());
}; //采购订单--取消订单

export var purchaseordercancel = function purchaseordercancel(params) {
  return get('/v1/purchase-order/cancel', params, pmsConfig());
}; //采购订单--回货视图

export var purchaseorderreturnview = function purchaseorderreturnview(params) {
  return get('/v1/purchase-order/return-view', params, pmsConfig());
}; //采购订单--添加回货

export var purchaseorderreturnadd = function purchaseorderreturnadd(params) {
  return post('/v1/purchase-order/return-add', params, pmsConfig());
}; //采购订单--终止剩余回货

export var purchaseorderreturndisuse = function purchaseorderreturndisuse(params) {
  return post('/v1/purchase-order/return-disuse', params, pmsConfig());
}; //采购订单--查看驳回/取消原因

export var purchaseorderreturnreason = function purchaseorderreturnreason(params) {
  return get('/v1/purchase-order/return-reason', params, pmsConfig());
}; //采购订单--创建

export var purchaseordercreate = function purchaseordercreate(params) {
  return post('/v1/purchase-order/create', params, pmsConfig());
}; //采购订单--查看详情

export var purchaseorderdetails = function purchaseorderdetails(params) {
  return get('/v1/purchase-order/details', params, pmsConfig());
}; //采购订单--终止剩余发货数/确认发货完成

export var purchaseorReturnFinish = function purchaseorReturnFinish(params) {
  return post('/v1/purchase-order/return-finish', params, pmsConfig());
}; //采购差异-列表

export var purchasedifferenceindex = function purchasedifferenceindex(params) {
  return get('/v1/purchase-difference/index', params, pmsConfig());
}; //采购差异-确认差异

export var purchasedifferenceconfirm = function purchasedifferenceconfirm(params) {
  return post('/v1/purchase-difference/confirm', params, pmsConfig());
}; //采购不良品--列表

export var purchasedifferencebadgoods = function purchasedifferencebadgoods(params) {
  return get('/v1/purchase-difference/bad-goods', params, pmsConfig());
}; //采购不良品--返修不良品

export var purchaserepairbadgoods = function purchaserepairbadgoods(params) {
  return post('/v1/purchase-difference/batch-goods-repair', params, pmsConfig());
}; //采购不良品--退货不良品

export var purchasereturnbadgoods = function purchasereturnbadgoods(params) {
  return post('/v1/purchase-difference/batch-goods-return', params, pmsConfig());
}; //采购不良品--报废不良品

export var purchasescrapbadgoods = function purchasescrapbadgoods(params) {
  return post('/v1/purchase-difference/scrap', params, pmsConfig());
}; //采购不良品--报废列表

export var purchaseScrapList = function purchaseScrapList(params) {
  return get('/v1/purchase-difference/scrap-list', params, pmsConfig());
}; //采购不良品--申请报废

export var purchaseApplyScrap = function purchaseApplyScrap(params) {
  return post('/v1/purchase-difference/apply-scrap', params, pmsConfig());
}; //采购不良品--审核报废

export var purchaseVerifyScrap = function purchaseVerifyScrap(params) {
  return post('/v1/purchase-difference/verify-scrap', params, pmsConfig());
}; //修改采购不良状态

export var updatebadsgoodsstatus = function updatebadsgoodsstatus(params) {
  return post('/v1/purchase-difference/update-bad-process-status', params, pmsConfig());
}; //修改采购不良备注

export var remarkbadgoods = function remarkbadgoods(params) {
  return post('/v1/purchase-difference/remark-bad-process', params, pmsConfig());
}; //采购不良品--合并退货（相同供应商）

export var purchasecombinereturn = function purchasecombinereturn(params) {
  return post('/v1/purchase-difference/combine-goods-return', params, pmsConfig());
}; // 采购价差异列表

export var purchasedifferencePriceindex = function purchasedifferencePriceindex(params) {
  return get('/v1/price-difference/index', params, pmsConfig());
}; // 采购价差异列表 日志

export var purchasedifferencePricelogs = function purchasedifferencePricelogs(params) {
  return get('/v1/price-difference/logs', params, pmsConfig());
}; // 采购价差异列表 修改备注

export var purchasedifferencePriceSavenote = function purchasedifferencePriceSavenote(params) {
  return get('/v1/price-difference/save-note', params, pmsConfig());
}; //采购订单-采购付款

export var purchasepaymentindex = function purchasepaymentindex(params) {
  return get('/v1/purchase-payment/index', params, pmsConfig());
}; //采购订单-申请付款视图

export var purchasepaymentapply = function purchasepaymentapply(params) {
  return get('/v1/purchase-payment/apply', params, pmsConfig());
}; //采购订单-申请付款保存

export var purchasepaymentapplysave = function purchasepaymentapplysave(params) {
  return post('/v1/purchase-payment/apply-save', params, pmsConfig());
}; //采购订单-批量申请付款保存

export var purchasepaymentbatchapplysave = function purchasepaymentbatchapplysave(params) {
  return post('/v1/purchase-payment/batch-apply-save', params, pmsConfig());
}; //采购订单-获取各状态汇总数据

export var purchaseordergetsumtotal = function purchaseordergetsumtotal(params) {
  return get('/v1/purchase-order/get-sum-total', params, pmsConfig());
}; //采购订单-采购付款各状态汇总数

export var purchasepaymentgetsumtotal = function purchasepaymentgetsumtotal(params) {
  return get('/v1/purchase-payment/get-sum-total', params, pmsConfig());
}; //采购订单-采购付款批量审核

export var purchasepaymentbatchreview = function purchasepaymentbatchreview(params) {
  return post('/v1/purchase-payment/batch-review', params, pmsConfig());
}; // 修改订单状态

export var purchaseorderupdateType = function purchaseorderupdateType(params) {
  return post('/v1/purchase-order/update-progress-type', params, pmsConfig());
}; // 修改订单进度备注

export var purchaseorderupdateprogressnote = function purchaseorderupdateprogressnote(params) {
  return post('/v1/purchase-order/update-progress-note', params, pmsConfig());
}; // 修改订单异常状态

export var purchaseorderupdateabnormalstatus = function purchaseorderupdateabnormalstatus(params) {
  return post('/v1/purchase-order/update-abnormal-status', params, pmsConfig());
}; // 修改采购订单

export var purchaseorderupdatepart = function purchaseorderupdatepart(params) {
  return post('/v1/purchase-order/update-part', params, pmsConfig());
}; // 批量修改采购订单

export var purchaseorderbatchupdatepart = function purchaseorderbatchupdatepart(params) {
  return post('/v1/purchase-order/batch-update-part', params, pmsConfig());
}; //财务付款审批-列表

export var financepaymentindex = function financepaymentindex(params) {
  return get('/v1/finance-payment/index', params, pmsConfig());
}; //财务付款审批-查看

export var financepaymentview = function financepaymentview(params) {
  return get('/v1/finance-payment/view', params, pmsConfig());
}; //财务付款审批-驳回

export var financepaymentreject = function financepaymentreject(params) {
  return post('/v1/finance-payment/reject', params, pmsConfig());
}; //财务付款审批-批量添加付款记录

export var financebatchpaymentpay = function financebatchpaymentpay(params) {
  return post('/v1/finance-payment/batch-pay', params, pmsConfig());
}; //财务付款审批-添加付款记录

export var financepaymentpay = function financepaymentpay(params) {
  return post('/v1/finance-payment/pay', params, pmsConfig());
}; //财务付款审批-添加付款记录

export var financepaymentpaylist = function financepaymentpaylist(params) {
  return get('/v1/finance-payment/pay-list', params, pmsConfig());
}; //财务付款单审批-获取各状态汇总数

export var financepaymentgetsumtotal = function financepaymentgetsumtotal(params) {
  return get('/v1/finance-payment/get-sum-total', params, pmsConfig());
}; //财务付款单审核-审核

export var financePaymentCheck = function financePaymentCheck(params) {
  return post('/v1/finance-payment/check', params, pmsConfig());
}; //财务付款单-相关联采购单支付信息

export var financepaymentrelationpaylist = function financepaymentrelationpaylist(params) {
  return get('/v1/finance-payment/relation-pay-list', params, pmsConfig());
}; //财务采购订单预付款管理--获取各状态汇总数

export var financeprepaymentgetsumtotal = function financeprepaymentgetsumtotal(params) {
  return get('/v1/purchase-prepayment/count', params, pmsConfig());
}; //财务采购订单预付款管理-列表

export var financeprepaymentindex = function financeprepaymentindex(params) {
  return get('/v1/purchase-prepayment/index', params, pmsConfig());
}; //财务采购订单预付款管理-申请付款

export var financeprepaymentview = function financeprepaymentview(params) {
  return get('/v1/purchase-prepayment/view', params, pmsConfig());
}; //财务采购订单预付款管理-申请付款

export var financepreApply = function financepreApply(params) {
  return post('/v1/purchase-prepayment/apply', params, pmsConfig());
}; //财务采购订单预付款管理-申请结算

export var financepreApplyFinish = function financepreApplyFinish(params) {
  return post('/v1/purchase-prepayment/apply-finish', params, pmsConfig());
}; //财务采购订单预付款管理-批量申请付款

export var financepreBatchApply = function financepreBatchApply(params) {
  return post('/v1/purchase-prepayment/batch-apply', params, pmsConfig());
}; //财务采购订单预付款管理-批量申请结算

export var financepreBatchApplyFinish = function financepreBatchApplyFinish(params) {
  return post('/v1/purchase-prepayment/batch-apply-finish', params, pmsConfig());
}; //财务采购订单预付款管理-查看详情

export var financepreDetailView = function financepreDetailView(params) {
  return get('/v1/purchase-prepayment/detail/view', params, pmsConfig());
}; //采购预付列表-取消申请

export var financepreDetailCancel = function financepreDetailCancel(params) {
  return post('/v1/purchase-prepayment/detail/cancel', params, pmsConfig());
}; //采购预付列表-驳回原因

export var financepreDetailRejectReason = function financepreDetailRejectReason(params) {
  return get('/v1/purchase-prepayment/detail/reject-reason', params, pmsConfig());
}; //采购预付列表-查看付款详情

export var financeprePayDetail = function financeprePayDetail(params) {
  return get('/v1/purchase-prepayment/pay-detail/view', params, pmsConfig());
}; //采购订单预付款导出

export var financeprepaymentexport = function financeprepaymentexport(params) {
  return get("/v1/purchase-prepayment/export", params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //采购订单预付款批量审核

export var financeprepaymentbatchreview = function financeprepaymentbatchreview(params) {
  return post('/v1/purchase-prepayment/batch-review', params, pmsConfig());
}; //银行信息列表

export var financebankinfoindex = function financebankinfoindex(params) {
  return get('/v1/bank-info/index', params, pmsConfig());
}; //银行信息、省会信息列表

export var financebankprovincecode = function financebankprovincecode(params) {
  return get('/v1/bank-info/bank-province-code', params, pmsConfig());
}; //新增银行信息

export var financebankinfoadd = function financebankinfoadd(params) {
  return post('/v1/bank-info/add', params, pmsConfig());
}; //修改银行信息

export var financebankinfoedit = function financebankinfoedit(params) {
  return post('/v1/bank-info/edit', params, pmsConfig());
}; //导入银行信息

export var financebankinfoimport = function financebankinfoimport(params, file) {
  return uploadNew('/v1/bank-info/import', params, file, pmsConfig());
}; //供货关系-待处理列表

export var supplyrelationunprocess = function supplyrelationunprocess(params) {
  return get('/v1/supply-relation/un-process', params, pmsConfig());
}; //根据产品编码获取采购价最低的供货关系

export var supplyrelationlowestprice = function supplyrelationlowestprice(params) {
  return get('/v1/supply-relation/lowest-price', params, pmsConfig());
}; //供货关系-查看供货关系是否存在

export var supplyrelationgetexists = function supplyrelationgetexists(params) {
  return get('/v1/supply-relation/get-exists', params, pmsConfig());
}; //采购付款单-取消申请

export var purchasepaymentcancelapply = function purchasepaymentcancelapply(params) {
  return get('/v1/purchase-payment/cancel-apply', params, pmsConfig());
}; //采购付款单-查看驳回原因

export var purchasepaymentrejectreason = function purchasepaymentrejectreason(params) {
  return get('/v1/purchase-payment/reject-reason', params, pmsConfig());
}; //采购订单-添加回货校验运单号重复

export var purchaseordercheckwaybill = function purchaseordercheckwaybill(params) {
  return get('/v1/purchase-order/check-waybill', params, pmsConfig());
}; //采购付款单-查看付款记录

export var purchasepaymentviewpay = function purchasepaymentviewpay(params) {
  return get('/v1/purchase-payment/view-pay', params, pmsConfig());
}; //上门质检-待质检列表

export var purchasewaitdoorqclist = function purchasewaitdoorqclist(params) {
  return get('/v1/purchase-order/wait-door-qc-list', params, pmsConfig());
}; //上门质检-待质检列表-导出

export var purchasewaitdoorqcexport = function purchasewaitdoorqcexport(params) {
  return get('/v1/purchase-order/wait-door-qc-export', params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //上门质检-质检

export var purchasedoorqccheckview = function purchasedoorqccheckview(params) {
  return get('/v1/purchase-order/door-qc-check-view', params, pmsConfig());
}; //上门质检-产前会

export var purchasedoorqcmeet = function purchasedoorqcmeet(params) {
  return post('/v1/purchase-order/door-qc-meet', params, pmsConfig());
}; //上门质检-打印产品会报告

export var purchasedoorqcprintmeet = function purchasedoorqcprintmeet(params) {
  return get('/v1/purchase-order/door-qc-print-meet', params, pmsConfig());
}; //采购订单-导出

export var purchaseorderexport = function purchaseorderexport(params) {
  return post('/v1/purchase-order/export', params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //采购订单-修改预计到货时间

export var purchasebatchUpdatePredictedArrival = function purchasebatchUpdatePredictedArrival(params) {
  return post('/v1/purchase-order/batchUpdatePredictedArrival', params, pmsConfig());
}; //采购付款-导出

export var purchasepaymentexport = function purchasepaymentexport(params) {
  return post('/v1/purchase-payment/export', params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //自动备货需求

export var demandCindex = function demandCindex(params) {
  return get('/v1/demand-c/index', params, pmsConfig());
}; //面料自动备货需求

export var demandMindex = function demandMindex(params) {
  return get('/v1/demand-m/index', params, pmsConfig());
}; //备货计划管理

export var prepareplanindex = function prepareplanindex(params) {
  return get('/v1/prepare-plan/index', params, pmsConfig());
}; //创建备货计划

export var prepareplancreate = function prepareplancreate(params) {
  return post('/v1/prepare-plan/create', params, pmsConfig());
}; //更新备货计划

export var prepareplanupdate = function prepareplanupdate(params) {
  return post('/v1/prepare-plan/update', params, pmsConfig());
}; //获取备货计划

export var prepareplandetail = function prepareplandetail(params) {
  return get('/v1/prepare-plan/detail', params, pmsConfig());
}; //取消备货计划

export var prepareplancancel = function prepareplancancel(params) {
  return post('/v1/prepare-plan/cancel', params, pmsConfig());
}; //删除备货计划

export var prepareplandelete = function prepareplandelete(params) {
  return post('/v1/prepare-plan/delete', params, pmsConfig());
}; //获取备货日志

export var prepareplanlog = function prepareplanlog(params) {
  return get('/v1/prepare-plan/log', params, pmsConfig());
}; //审核备货计划

export var prepareplanverify = function prepareplanverify(params) {
  return post('/v1/prepare-plan/verify', params, pmsConfig());
}; //驳回人工备货

export var prepareplanreject = function prepareplanreject(params) {
  return post('/v1/prepare-plan/reject', params, pmsConfig());
}; //批量导出备货计划

export var preparePlanexport = function preparePlanexport(params) {
  return get('/v1/prepare-plan/export', params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //导入备货计划
// export const preparePlanimport = params => post('/v1/prepare-plan/import',params,pmsConfig())

export var preparePlanimport = function preparePlanimport(params, file) {
  return uploadNew('/v1/prepare-plan/import', params, file, pmsConfig());
}; //自动备货需求日志

export var demanClog = function demanClog(params, file) {
  return get('/v1/demand-c/log', params, pmsConfig());
}; //面料自动备货需求日志

export var demanMlog = function demanMlog(params, file) {
  return get('/v1/demand-m/log', params, pmsConfig());
}; // 采购需求列表-详情-获取洗水唛

export var getWaterMark = function getWaterMark(params) {
  return get("/v1/purchase-order/care-detail", params, pmsConfig());
}; // 采购不良品退款

export var purchaseOrderRefundList = function purchaseOrderRefundList(params) {
  return get("/v1/purchase-order/refund", params, pmsConfig());
};
export var purchaseOrderRefundCancel = function purchaseOrderRefundCancel(params) {
  return post('/v1/purchase-order/refund/cancel', params, pmsConfig());
};
export var purchaseOrderRefundApply = function purchaseOrderRefundApply(params) {
  return post('/v1/purchase-order/refund/apply', params, pmsConfig());
}; // 采购订单抵扣列表

export var purchaseOrderDeductionList = function purchaseOrderDeductionList(params) {
  return get("/v1/purchase-order/deduction", params, pmsConfig());
};
export var purchaseOrderDeductionCanApplyPay = function purchaseOrderDeductionCanApplyPay(params) {
  return get("/v1/purchase-order/deduction/can-apply-pay", params, pmsConfig());
};
export var purchaseOrderDeduction = function purchaseOrderDeduction(params) {
  return post("/v1/purchase-order/deduction", params, pmsConfig());
};
export var purchaseOrderDeductionVerify = function purchaseOrderDeductionVerify(params) {
  return post("/v1/purchase-order/deduction/verify", params, pmsConfig());
};
export var purchaseOrderDeductionImport = function purchaseOrderDeductionImport(params, file) {
  return uploadNew("/v1/purchase-order/deduction/import", params, file, pmsConfig());
};
export var purchaseOrderDeductionExport = function purchaseOrderDeductionExport(params) {
  return get("/v1/purchase-order/deduction/export", params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 采购日报

export var baseDailyReport = function baseDailyReport(params) {
  return get("/v1/daily-report/index", params, pmsConfig());
};
export var trendDailyReport = function trendDailyReport(params) {
  return get("/v1/daily-report/trend", params, pmsConfig());
};
export var supplierDailyReportExport = function supplierDailyReportExport(params) {
  return get("/v1/supplier/daily-report/export", params, pmsConfig());
};
export var supplierWeeklyReportExport = function supplierWeeklyReportExport(params) {
  return get("/v1/supplier/weekly-report/export", params, pmsConfig());
};
export var exportList = function exportList(params) {
  return get("/v1/export/list", params, pmsConfig());
}; // 采购指标

export var targetindex = function targetindex(params) {
  return get("/v1/target/index", params, pmsConfig());
};
export var targetconfigs = function targetconfigs(params) {
  return get("/v1/target/configs", params, pmsConfig());
};
export var targetcreate = function targetcreate(params) {
  return post("/v1/target/create", params, pmsConfig());
};
export var targetupdate = function targetupdate(params) {
  return post("/v1/target/update", params, pmsConfig());
};
export var targetdelete = function targetdelete(params) {
  return get("/v1/target/delete", params, pmsConfig());
};
export var targetpurchaserstat = function targetpurchaserstat(params) {
  return get("/v1/target/purchaser-stat", params, pmsConfig());
};
export var targetpurchaserstatexport = function targetpurchaserstatexport(params) {
  return get("/v1/target/purchaser-stat/export", params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
};
export var targetsupplierstat = function targetsupplierstat(params) {
  return get("/v1/target/supplier-stat", params, pmsConfig());
};
export var targetsupplierstatexport = function targetsupplierstatexport(params) {
  return get("/v1/target/supplier-stat/export", params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //缺货解决时效记录

export var lackresolutiontimerecordindex = function lackresolutiontimerecordindex(params) {
  return get("/v1/lack-resolution-time-record/index", params, pmsConfig());
};
export var lackresolutiontimerecordexport = function lackresolutiontimerecordexport(params) {
  return get("/v1/lack-resolution-time-record/export", params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //SKC议价

export var skcbargainindex = function skcbargainindex(params) {
  return get("/v1/skc-bargain/index", params, pmsConfig());
};
export var skcbargaincreate = function skcbargaincreate(params) {
  return post("/v1/skc-bargain/create", params, pmsConfig());
};
export var skcbargainupdate = function skcbargainupdate(params) {
  return post("/v1/skc-bargain/update", params, pmsConfig());
};
export var skcbargaincancel = function skcbargaincancel(params) {
  return get("/v1/skc-bargain/cancel", params, pmsConfig());
};
export var skcbargainend = function skcbargainend(params) {
  return get("/v1/skc-bargain/end", params, pmsConfig());
};
export var skcbargainimport = function skcbargainimport(params, file) {
  return uploadNew("/v1/skc-bargain/import", params, file, pmsConfig());
};
export var skcbargainexport = function skcbargainexport(params) {
  return get("/v1/skc-bargain/export", params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 采购单缸差

export var dyeLotChromatismList = function dyeLotChromatismList(params) {
  return get("/v1/purchase-order/dye-lot-chromatism", params, pmsConfig());
};
export var dyeLotChromatismCreate = function dyeLotChromatismCreate(params) {
  return post('/v1/purchase-order/dye-lot-chromatism', params, pmsConfig());
};
export var dyeLotChromatismDelete = function dyeLotChromatismDelete(params) {
  return post('/v1/purchase-order/dye-lot-chromatism/delete', params, pmsConfig());
};
export var dyeLotChromatismImport = function dyeLotChromatismImport(params, file) {
  return uploadNew('/v1/purchase-order/dye-lot-chromatism/import', params, file, pmsConfig());
}; //SKC转打版

export var skcConvertMakingIndex = function skcConvertMakingIndex(params) {
  return get('/v1/skc-convert-making/index', params, pmsConfig());
};
export var skcConvertMakingCreate = function skcConvertMakingCreate(params) {
  return post('/v1/skc-convert-making/create', params, pmsConfig());
};
export var skcConvertMakingUpdate = function skcConvertMakingUpdate(params) {
  return post('/v1/skc-convert-making/update', params, pmsConfig());
};
export var skcConvertMakingApprovalView = function skcConvertMakingApprovalView(params) {
  return get('/v1/skc-convert-making/approval', params, pmsConfig());
};
export var skcConvertMakingApproval = function skcConvertMakingApproval(params) {
  return post('/v1/skc-convert-making/approval', params, pmsConfig());
};
export var skcConvertMakingPush = function skcConvertMakingPush(params) {
  return get('/v1/skc-convert-making/push', params, pmsConfig());
};
export var skcConvertMakingCancel = function skcConvertMakingCancel(params) {
  return get('/v1/skc-convert-making/cancel', params, pmsConfig());
};
export var skcConvertMakingImport = function skcConvertMakingImport(params, file) {
  return uploadNew("/v1/skc-convert-making/import", params, file, pmsConfig());
};
export var skcConvertMakingExport = function skcConvertMakingExport(params) {
  return get("/v1/skc-convert-making/export", params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //缺货跟单

export var lackFollowIndex = function lackFollowIndex(params) {
  return get("/v1/lack-follow/index", params, pmsConfig());
};
export var lackFollowExport = function lackFollowExport(params) {
  return get("/v1/lack-follow/export", params, pmsConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //付款主体

export var paymentSubjectList = function paymentSubjectList(params) {
  return get("/v1/payment-subject/list", params, pmsConfig());
};
export var paymentSubjectCreate = function paymentSubjectCreate(params) {
  return post("/v1/payment-subject/create", params, pmsConfig());
};
export var paymentSubjectUpdate = function paymentSubjectUpdate(params) {
  return post("/v1/payment-subject/update", params, pmsConfig());
};
export var paymentSubjectImport = function paymentSubjectImport(params, file) {
  return uploadNew("/v1/payment-subject/import", params, file, pmsConfig());
};