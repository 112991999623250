import "core-js/modules/es6.array.find";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { logout as _logout } from '@/api/user';
import Menus from "./menus";
import { toLoginPage } from '@/core/auth';
import _ from 'lodash';
export default {
  components: {
    Menus: Menus
  },
  data: function data() {
    return {
      isMenu: false,
      //是不是在菜单显示的状态
      activeMenuId: 0,
      menuTimer: null
    };
  },
  created: function created() {},
  methods: {
    menuEnter: function menuEnter(id) {
      clearTimeout(this.menuTimer);
      this.activeMenuId = id || this.activeMenuId;
      this.isMenu = true;
    },
    menuLeave: function menuLeave() {
      var _this = this;

      this.menuTimer = setTimeout(function () {
        _this.isMenu = false;
      }, 300);
    },
    goRoute: function goRoute(name) {
      name && this.$router.push({
        name: name,
        query: {
          t: String(Math.random()).replace('.', '')
        }
      });
    },
    //登出
    logout: function logout() {
      _logout().then(function (_ref) {
        var code = _ref.code;

        if (code === SUCCESSCODE) {
          toLoginPage();
        }
      });
    }
  },
  computed: {
    activeMenus: function activeMenus() {
      return this.isMenu ? _.find(this.menuTree, {
        id: this.activeMenuId
      }).children || [] : [];
    },
    menuTree: function menuTree() {
      return this.$store.state.app.menuTree;
    }
  }
};