import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { get, post, upload, commonConfig } from '@/api';
/**
 * TODO 公用api
 */
//公共配置

export var configcommonConfig = function configcommonConfig(params) {
  return get('/config/commonConfig', params, commonConfig());
}; //上传图片

export var uploadImage = function uploadImage(params) {
  return post('/uploadImage', params, commonConfig());
}; //上传文件

export var uploadXls = function uploadXls(params) {
  return post('/uploadFile', params, commonConfig());
}; //所有国家

export var allCountryList = function allCountryList(params) {
  return get('/country/country', params, commonConfig({
    loading: false
  }));
}; //平台管理

export var platformlist = function platformlist(params) {
  return post('/platform/list', params, commonConfig());
}; //平台管理添加

export var platformadd = function platformadd(params) {
  return post('/platform/add', params, commonConfig());
}; //平台管理编辑

export var platformedit = function platformedit(params) {
  return post('/platform/edit', params, commonConfig());
}; //平台日志

export var platformlog = function platformlog(params) {
  return get('/platform/log', params, commonConfig());
}; //所有平台

export var platformplatform = function platformplatform(params) {
  return get('/platform/platform', params, commonConfig({
    loading: false
  }));
}; //店铺列表

export var accountlist = function accountlist(params) {
  return post('/account/list', params, commonConfig());
}; //店铺编辑

export var accountedit = function accountedit(params) {
  return post('/account/edit', params, commonConfig());
}; //店铺添加

export var accountadd = function accountadd(params) {
  return post('/account/add', params, commonConfig());
}; //店铺日志

export var accountlog = function accountlog(params) {
  return get('/account/log', params, commonConfig());
}; //所有店铺

export var accountaccount = function accountaccount(params) {
  return get('/account/account', params, commonConfig());
}; //自动获取店铺编码

export var accountaccountcode = function accountaccountcode(params) {
  return get('/account/account-code', params, commonConfig());
}; //国家列表

export var countrylist = function countrylist(params) {
  return post('/country/list', params, commonConfig());
}; //国家添加

export var countryadd = function countryadd(params) {
  return post('/country/add', params, commonConfig());
}; //国家编辑

export var countryedit = function countryedit(params) {
  return post('/country/edit', params, commonConfig());
}; //国家日志

export var countrylog = function countrylog(params) {
  return get('/country/log', params, commonConfig());
}; //币种列表

export var currencylist = function currencylist(params) {
  return post('/currency/list', params, commonConfig());
}; //币种添加

export var currencyadd = function currencyadd(params) {
  return post('/currency/add', params, commonConfig());
}; //币种编辑

export var currencyedit = function currencyedit(params) {
  return post('/currency/edit', params, commonConfig());
}; //币种日志

export var currencylog = function currencylog(params) {
  return get('/currency/log', params, commonConfig());
}; //所有币种

export var currencycurrency = function currencycurrency(params) {
  return get('/currency/currency', params, commonConfig({
    loading: false
  }));
}; //汇率列表

export var exchangeratelist = function exchangeratelist(params) {
  return post('/exchange-rate/list', params, commonConfig());
}; //汇率添加

export var exchangerateadd = function exchangerateadd(params) {
  return post('/exchange-rate/add', params, commonConfig());
}; //汇率编辑

export var exchangerateedit = function exchangerateedit(params) {
  return post('/exchange-rate/edit', params, commonConfig());
}; //汇率日志

export var exchangeratelog = function exchangeratelog(params) {
  return get('/exchange-rate/log', params, commonConfig());
}; //搜索自营站点

export var searchProprietaryWebsite = function searchProprietaryWebsite(params) {
  return get('/website/searchProprietaryWebsite', _objectSpread({
    status: 1
  }, params), commonConfig());
}; //搜索站点

export var WebsitesearchWebsite = function WebsitesearchWebsite(params) {
  return get('/website/searchWebsite', params, commonConfig());
}; //通过店铺编码获取店铺信息

export var accountbycode = function accountbycode(params) {
  return get('/account/account-by-code', params, commonConfig());
}; //系统配置列表

export var systemconfiglist = function systemconfiglist(params) {
  return get('/system-config/list', params, commonConfig());
}; //系统配置编辑

export var systemconfigedit = function systemconfigedit(params) {
  return post('/system-config/edit', params, commonConfig());
}; //获取配置

export var systemconfigconfigs = function systemconfigconfigs(params) {
  return get('/system-config/configs', params, commonConfig());
}; //通过配置获取用户权限

export var systemConfigUserPermissions = function systemConfigUserPermissions(params) {
  return get('/system-config/user-permissions', params, commonConfig());
}; // platform_codes获取店铺

export var accountaccountplatformcodes = function accountaccountplatformcodes(params) {
  return get('/account/account-by-platform-codes', params, commonConfig());
}; //拉取钉钉用户ID

export var userPushDdUserId = function userPushDdUserId(params) {
  return get('/user/push/dd-user-id', params, commonConfig());
};