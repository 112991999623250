import { get, post, userConfig } from '@/api';
/**
 * TODO 公用api
 */
//登录

export var login = function login(params) {
  return post('/login', params, userConfig());
}; //登出

export var logout = function logout(params) {
  return get('/logout', params, userConfig());
}; //当前用户 /

export var user = function user(params) {
  return get('/users/user', params, userConfig());
}; //刷新token

export var refreshtoken = function refreshtoken(params) {
  return post('/refreshtoken', params, userConfig());
}; // 权限菜单

export var getMenusRoute = function getMenusRoute(params) {
  return get('/menus/route', params, userConfig());
}; //菜单列表

export var menuslist = function menuslist(params) {
  return get('/menus/list', params, userConfig());
}; //添加菜单

export var menusadd = function menusadd(params) {
  return post('/menus/add', params, userConfig());
}; //菜单编辑

export var menusedit = function menusedit(params) {
  return post('/menus/edit', params, userConfig());
}; //删除菜单

export var menusdelete = function menusdelete(params) {
  return post('/menus/delete', params, userConfig());
}; //添加用户

export var usersadd = function usersadd(params) {
  return post('/users/add', params, userConfig());
}; //编辑用户

export var usersedit = function usersedit(params) {
  return post('/users/edit', params, userConfig());
}; //编辑用户

export var usersdelete = function usersdelete(params) {
  return post('/users/delete', params, userConfig());
}; //编辑用户

export var userslist = function userslist(params) {
  return post('/users/list', params, userConfig());
}; //用户搜索

export var usersdata = function usersdata(params) {
  return get('/users/user-data', params, userConfig({
    loading: false
  }));
}; //用户下拉列表

export var userselect = function userselect(params) {
  return get('/users/user-select', params, userConfig({
    loading: false
  }));
}; //角色相关
//添加角色

export var roleadd = function roleadd(params) {
  return post('/role/add', params, userConfig());
}; //角色编辑

export var roleedit = function roleedit(params) {
  return post('/role/edit', params, userConfig());
}; //角色删除

export var roledelete = function roledelete(params) {
  return post('/role/delete', params, userConfig());
}; //角色列表

export var rolelist = function rolelist(params) {
  return post('/role/list', params, userConfig());
}; //角色下拉列表

export var roleroles = function roleroles(params) {
  return post('/role/roles', params, userConfig());
}; //角色查看数据

export var rolemenuget = function rolemenuget(params) {
  return get('/role/menu', params, userConfig());
}; //角色菜单保存1

export var rolesave = function rolesave(params) {
  return post('/role/save', params, userConfig());
}; //权限相关
//添加角色

export var permissionsadd = function permissionsadd(params) {
  return post('/permissions/add', params, userConfig());
}; //角色编辑

export var permissionsedit = function permissionsedit(params) {
  return post('/permissions/edit', params, userConfig());
}; //角色删除

export var permissionsdelete = function permissionsdelete(params) {
  return post('/permissions/delete', params, userConfig());
}; //角色列表

export var permissionslist = function permissionslist(params) {
  return post('/permissions/list', params, userConfig());
}; // export const user = params => get('/v1/stock/index', params, userConfig({base: '//wms.test.nadiera.com'}));