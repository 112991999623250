import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.find";
import _defineProperty from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import _toConsumableArray from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.number.constructor";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from 'lodash';
import queryList from '@/components/base-select-query/map';
export default {
  name: 'BaseMultiple',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Array],
      default: function _default() {
        return [];
      }
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    width: {
      type: [Number, String],
      default: null
    },
    //是否要搜索  默认是要的
    search: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    selectStyle: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    apiName: {
      type: String,
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: false
    },
    // 是否在输入框中显示缩略值
    collapseShowValue: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      isShow: false,
      //是不是显示的
      filterText: '',
      //过滤文本
      newOptions: [],
      inputHovering: false
    };
  },
  watch: {
    options: {
      immediate: true,
      handler: function handler(v) {
        this.intalOptions();
      }
    },
    value: function value(v1) {
      if (!_.isEqual(v1, this.selectedValues)) {
        this.setValue();
      }
    }
  },
  created: function created() {
    var _this = this;

    if (this.apiName) {
      queryList(this.apiName).then(function (list) {
        var _this$options;

        (_this$options = _this.options).splice.apply(_this$options, [0, _this.options.length].concat(_toConsumableArray(list)));
      });
    }
  },
  mounted: function mounted() {},
  methods: {
    setChecked: function setChecked(item, checked) {
      this.$set(item, 'checked', checked);
    },
    //初始化options  避免污染了传过来的options
    intalOptions: function intalOptions() {
      var _this2 = this;

      var newOptions = this.options.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          checked: (_.find(_this2.newOptions, {
            value: item.value
          }) || {}).checked || _this2.value && _this2.value.includes(item.value) || false
        });
      });
      this.newOptions = newOptions;
    },

    /**
     * TODO popover打开的时候处理的逻辑
     */
    openPopover: function openPopover() {
      var _this3 = this;

      this.isShow = true;
      this.filterText = '';
      this.search && this.$refs.inputRef && this.$nextTick(function () {
        return _this3.$refs.inputRef.focus();
      });
    },
    setValue: function setValue() {
      var _this4 = this;

      this.newOptions.forEach(function (item) {
        _this4.setChecked(item, _this4.value.includes(item.value));
      });
    },
    checkboxChange: function checkboxChange() {
      this.$emit('input', this.selectedValues);
      this.$emit('change', this.selectedValues);
    },
    // 清除按钮点击
    handleClearClick: function handleClearClick() {
      this.allChecked = false;
    }
  },
  computed: {
    filterOptions: function filterOptions() {
      var _this5 = this;

      if (!this.filterText) {
        return this.newOptions;
      }

      return this.newOptions.filter(function (_ref) {
        var label = _ref.label;
        return !!~label.toLocaleLowerCase().indexOf(_this5.filterText.toLocaleLowerCase());
      });
    },
    //当前选中的全部item
    selectItems: function selectItems() {
      return this.newOptions.filter(function (_ref2) {
        var checked = _ref2.checked;
        return checked;
      });
    },
    //当前选中的值
    selectedValues: function selectedValues() {
      return this.selectItems.map(function (_ref3) {
        var value = _ref3.value;
        return value;
      });
    },

    /**
     * 选中的当前展示的值
     * 如果是分组显示的话   那么分组选中的跟不是分组选中的是不一样的
     */
    showValue: function showValue() {
      var selected = this.selectItems;

      if (this.collapseShowValue) {
        var text = '';

        if (this.allChecked) {
          text = '全部选中';
        }

        if (text) {
          return text;
        }

        if (!selected.length) {
          text = '';
        } else if (selected.length > 1) {
          text = "".concat(selected.length, "\u4E2A\u9009\u4E2D");
        } else {
          text = "".concat(selected[0].label);
        }

        return text;
      } else {
        if (selected.length > 0) {
          return selected.map(function (item) {
            return item.label;
          }).join(',');
        } else {
          return '';
        }
      }
    },
    //是不是全选
    allChecked: {
      get: function get() {
        var len = this.newOptions.length;
        return len && this.newOptions.filter(function (_ref4) {
          var checked = _ref4.checked;
          return checked;
        }).length === len;
      },
      set: function set(val) {
        this.filterOptions.forEach(function (item) {
          item.checked = val;
        });
        this.checkboxChange();
      }
    },
    //横岗
    isIndeterminate: function isIndeterminate() {
      return !!(this.newOptions.length && !this.allChecked && this.selectItems.length);
    },
    //宽度
    pxwidth: function pxwidth() {
      if (this.width) {
        return "".concat(this.width, "px").replace(/(px)+/g, 'px');
      } else {
        return this.width;
      }
    },
    // 是否显示清除按钮
    showClose: function showClose() {
      return !this.disabled && this.clearable && this.inputHovering && this.selectItems && this.selectItems.length > 0;
    }
  }
};