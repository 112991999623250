var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "erp-app" },
    [
      _c("Header"),
      _vm._v(" "),
      _c("routerTab"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "erp-container" },
        [
          _vm.crumbs.length
            ? _c("crumbs", { attrs: { crumbs: _vm.crumbs } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "keep-alive",
            {
              attrs: { include: _vm.cashViews, exclude: _vm.excludeCacheViews },
            },
            [
              _vm.isRouterReload
                ? _c("router-view", { ref: "route" })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }