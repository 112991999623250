import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
import { categorytree } from '@/api/product';
import { transforCateTree } from '@/core/provide';
export default {
  name: 'BaseProductCategory',
  inheritAttrs: false,
  props: {
    checkStrictly: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Array],
      default: function _default() {
        return [];
      }
    },
    filterable: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      options: [],
      category: this.value,
      props: {
        value: 'category_code',
        checkStrictly: this.checkStrictly
      }
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(data) {
        this.category = data;
      }
    }
  },
  created: function created() {
    var _this = this;

    categorytree().then(function (res) {
      if (res.code === SUCCESSCODE) {
        _this.options = transforCateTree(res.data);
      }
    });
  },
  methods: {
    //搜索
    cateTreeFind: function cateTreeFind(node, keyword) {
      keyword = keyword.trim();
      if (!keyword) return true;
      return node.data.__labels.toLowerCase().includes(keyword.toLowerCase());
    }
  }
};