import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: '请输入内容'
    },
    size: {
      type: String,
      default: 'small'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    reg: {
      type: RegExp,
      default: undefined
    },
    //限制最大能输入多少的数字
    maxNumber: {
      type: Number,
      default: 0
    }
  },
  watch: {
    input: function input(v1, v2) {
      var _this = this;

      if (this.maxNumber && parseFloat(this.input) > this.maxNumber) {
        this.input = this.maxNumber;
        this.$emit('input', this.maxNumber);
        return;
      } //如果当前的值跟匹配后的值是一样也不做任何事情


      if (v1 === this.getVal(v1)) {
        return this.$emit('input', v1);
      } else {
        this.$nextTick(function () {
          _this.input = v2;

          _this.$emit('input', _this.input);
        });
      }
    },
    value: function value(v1) {
      if (this.input !== v1) {
        this.input = _.isNumber(v1) && !_.isNaN(v1) ? String(v1) : v1;
      }
    }
  },
  data: function data() {
    return {
      input: this.value
    };
  },
  methods: {
    //这是获取一个当前有效的输入框值
    getVal: function getVal(v1) {
      return this.reg ? (String(v1 || '').match(new RegExp(this.reg, 'g')) || [])[0] || '' : v1;
    }
  }
};