//
//
//
//
//
//
//
//
//
export default {
  name: 'FormItem',
  props: {
    label: {
      type: String,
      default: ''
    }
  }
};