import "core-js/modules/es6.function.name";
import { TOKEN_KEY, SECRET, REFRESH_TOKEN, CACHE_TAB } from '@/core/types';
import { setLocal, removeLocal, getLocal, getCookie, removeCookie } from '@/core/provide';
export var getToken = function getToken() {
  return getLocal(TOKEN_KEY);
};
export var getSecret = function getSecret() {
  return getLocal(SECRET);
};
export var getRefreshToken = function getRefreshToken() {
  return getLocal(REFRESH_TOKEN);
};
export var getCookieToken = function getCookieToken() {
  return getCookie(TOKEN_KEY);
}; //设置本地数据

export var setLocalInfo = function setLocalInfo(payload) {
  var token_type = payload.token_type,
      access_token = payload.access_token,
      secret = payload.secret,
      refresh_token = payload.refresh_token;
  setLocal(TOKEN_KEY, "".concat(token_type, " ").concat(access_token));
  setLocal(SECRET, secret);
  setLocal(REFRESH_TOKEN, refresh_token);
}; //删除本地数据

export var removeLocalInfo = function removeLocalInfo() {
  removeLocal(TOKEN_KEY);
  removeLocal(SECRET);
  removeLocal(REFRESH_TOKEN);
}; //去登录页面

export var toLoginPage = function toLoginPage() {
  removeLocalInfo();
  window.location.href = '/login';
};
export var replaceCookieToken = function replaceCookieToken() {
  setLocal(TOKEN_KEY, getCookie(TOKEN_KEY));
  setLocal(SECRET, getCookie(SECRET));
  setLocal(REFRESH_TOKEN, getCookie(REFRESH_TOKEN));
  removeCookie(TOKEN_KEY, {
    domain: process.env.VUE_APP_MAIN_DOMAIN
  });
  removeCookie(SECRET, {
    domain: process.env.VUE_APP_MAIN_DOMAIN
  });
  removeCookie(REFRESH_TOKEN, {
    domain: process.env.VUE_APP_MAIN_DOMAIN
  });
};
export var setCacheTab = function setCacheTab(list) {
  setLocal(CACHE_TAB, JSON.stringify(list.map(function (_ref) {
    var fullPath = _ref.fullPath,
        hash = _ref.hash,
        meta = _ref.meta,
        name = _ref.name,
        params = _ref.params,
        path = _ref.path,
        query = _ref.query;
    return {
      fullPath: fullPath,
      hash: hash,
      meta: meta,
      name: name,
      params: params,
      path: path,
      query: query
    };
  })));
};
export var getCacheTab = function getCacheTab() {
  var cacheTab = getLocal(CACHE_TAB);

  if (cacheTab) {
    try {
      return JSON.parse(cacheTab);
    } catch (e) {
      return [];
    }
  }

  return [];
};