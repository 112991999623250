var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          attrs: { "remote-method": _vm.remoteMethod },
          model: {
            value: _vm.selectValue,
            callback: function ($$v) {
              _vm.selectValue = $$v
            },
            expression: "selectValue",
          },
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._t("prefix", null, { slot: "prefix" }),
      _vm._v(" "),
      _vm._l(_vm.options, function (item) {
        return _c("el-option", {
          key: item.value,
          attrs: {
            label: item.label,
            value: item.value,
            disabled: item.disabled,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }