import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { CACHE_TAB_CLICK, SPLICE_CACHE_TABLE } from '@/store/types';
import _ from 'lodash';
import { transTabTitle } from '@/core/get-page-title';
export default {
  name: 'router-tab',
  inject: ['reload'],
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {// $(this.$el).off('mousewheel DOMMouseScroll',this.mousewheel)
    // $(this.$el).on('mousewheel DOMMouseScroll', this.mousewheel)  
  },
  methods: {
    routeTabChange: function routeTabChange() {
      if (this.$el) {
        var $list = $(this.$el).find('.router-list');
        var width = 0;
        $list.children().each(function () {
          width += $(this).outerWidth();
        });
        $list.css('min-width', width);
        var scrollBarWidth = $(this.$el).find('.el-scrollbar__bar').width();
        this.isScroll = width > scrollBarWidth;

        if (width > scrollBarWidth) {
          $(this.$el).find('.el-scrollbar__wrap').scrollLeft(scrollBarWidth);
          $('.el-scrollbar__thumb').css('width', scrollBarWidth / width * 100 + '%');
        } else {
          $('.el-scrollbar__thumb').css('width', 0);
        }
      }
    },
    isActive: function isActive(item) {
      return item.name === this.$route.name;
    },
    goPage: function goPage(item) {
      this.$store.commit("app/".concat(CACHE_TAB_CLICK), true);
      this.$router.push({
        path: item.fullPath
      });
    },
    close: function close(index, item) {
      this.$$removeCacheTab(item.name);

      if (!this.isActive(item)) {
        return;
      }

      var last = this.cacheTab[this.cacheTab.length - 1];

      if (last) {
        this.goPage(last);
      } else {
        this.$router.push({
          name: 'home'
        });
      }
    },
    getTabTitle: function getTabTitle(route) {
      return transTabTitle(route) || (_.find(this.$store.state.app.menus, {
        uri: route.name
      }) || {}).name || route.meta.title || '';
    }
  },
  computed: {
    cacheTab: function cacheTab() {
      return this.$store.state.app.cacheTab;
    }
  }
};