import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import Layout from '@/layout';
export var createRouteItem = function createRouteItem(path, name, component, meta) {
  return {
    path: path,
    name: name,
    component: component,
    meta: meta
  };
}; //layout的

export var layoutItem = function layoutItem(path, children, name, redirect) {
  return {
    path: path,
    component: Layout,
    name: name,
    redirect: redirect,
    children: children
  };
};
/**
 * TODO 创建 路由的meta
 * @param {String} [title] - 当前菜单的标题
 * @param {String} [routeParent] - 如果当前菜单不在菜单配置下面的话  当前菜单指向的配置菜单模块的父级   菜单权限控制   面包线控制
 * @param {String} [tabName] - 标签名称 如果想多个路由公用一个缓存标签名称的话 就加上这个
 * @param {Object} [options] - meta 扩展参数
 */

export var createMeta = function createMeta() {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var routeParent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var tabName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return _objectSpread({
    title: title,
    routeParent: routeParent,
    tabName: tabName
  }, options);
};