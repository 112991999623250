import { createRouteItem, layoutItem, createMeta } from "./unit.js";

var UserList = function UserList() {
  return import('@/views/user/list');
}; //用户列表


var UserRoles = function UserRoles() {
  return import('@/views/user/roles');
}; //角色列表


var UserMenus = function UserMenus() {
  return import('@/views/user/menus');
}; //菜单列表


var UserPermissions = function UserPermissions() {
  return import('@/views/user/permissions');
}; //权限列表
//基础设置


var UserBasisAccount = function UserBasisAccount() {
  return import('@/views/user/basis-account');
}; //销售账号管理


var UserBasisPlatform = function UserBasisPlatform() {
  return import('@/views/user/basis-platform');
}; //销售平台管理


var UserBasisCountry = function UserBasisCountry() {
  return import('@/views/user/basis-country');
}; //国家管理


var UserBasisCurrency = function UserBasisCurrency() {
  return import('@/views/user/basis-currency');
}; //币种管理


var UserBasisRate = function UserBasisRate() {
  return import('@/views/user/basis-rate');
}; //汇率管理


var UserSystemConfig = function UserSystemConfig() {
  return import('@/views/user/system-config');
}; //系统配置管理


var UserLogistics = function UserLogistics() {
  return import('@/views/user/logistics');
}; //物流服务商列表


var UserLogisticsChannel = function UserLogisticsChannel() {
  return import('@/views/user/logistics-channel');
}; //物流渠道列表


var UserBasisChannel = function UserBasisChannel() {
  return import('@/views/user/basis-channel');
}; //物流渠道列表
//用户相关


export default layoutItem('/user', [//用户列表
createRouteItem('list', 'user-list', UserList, createMeta('用户列表')), //角色列表
createRouteItem('roles', 'user-roles', UserRoles, createMeta('角色列表')), //菜单列表
createRouteItem('menus', 'user-menus', UserMenus, createMeta('菜单列表')), //权限列表
createRouteItem('permissions', 'user-permissions', UserPermissions, createMeta('权限列表')), //销售账号管理
createRouteItem('basis-account', 'user-basis-account', UserBasisAccount, createMeta('销售账号管理')), //销售平台管理
createRouteItem('basis-platform', 'user-basis-platform', UserBasisPlatform, createMeta('销售平台管理')), //国家管理
createRouteItem('basis-country', 'user-basis-country', UserBasisCountry, createMeta('国家管理')), //币种管理
createRouteItem('basis-currency', 'user-basis-currency', UserBasisCurrency, createMeta('币种管理')), //汇率管理
createRouteItem('basis-rate', 'user-basis-rate', UserBasisRate, createMeta('汇率管理')), //系统配置管理
createRouteItem('system-config', 'user-system-config', UserSystemConfig, createMeta('系统配置管理')), //物流服务商列表
createRouteItem('logistics', 'user-logistics', UserLogistics, createMeta('物流服务商列表')), //用户物流渠道列表
createRouteItem('logistics-channel', 'user-logistics-channel', UserLogisticsChannel, createMeta('用户物流渠道列表')), //基础物流渠道列表
createRouteItem('basis-channel', 'user-basis-channel', UserBasisChannel, createMeta('基础物流渠道列表'))]);