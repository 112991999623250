var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-input", {
    attrs: {
      type: "textarea",
      autosize: "",
      placeholder: "请输入关键词后，回车键输入...",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }