var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "m-b-20" },
    [
      _vm.title
        ? _c(
            "div",
            {
              staticClass: "flex flex-jcsb",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _vm._t("right"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }