import Vue from 'vue';
import Router from 'vue-router';
import Vm404 from '@/views/404';
import Vm403 from '@/views/403';
import { createRouteItem, layoutItem, createMeta } from "./unit.js";
import productRouters from "./product"; //产品相关

import stockRouters from "./stock"; //仓库相关

import userRouters from "./user"; //用户相关

import robotsRouters from "./robots"; //爬虫相关

import orderRouters from "./order"; //订单相关

import pmsRouters from "./pms"; //采购相关

import finance from "./finance"; //财务管理

import logistics from "./logistics"; //物流渠道

import publish from "./publish"; //刊登模块

import report from "./report"; //报表模块

var Login = function Login() {
  return import('@/views/login');
}; //登录页


var Home = function Home() {
  return import('@/views/home');
}; //首页


Vue.use(Router);
export var constantRoutes = [createRouteItem('/login', 'login', Login), //登录页面
layoutItem('/', [createRouteItem('home', 'home', Home, createMeta('首页'))], '', '/home'), //首页
layoutItem('/', [createRouteItem('/404', '404', Vm404), createRouteItem('/403', '403', Vm403)]), productRouters, stockRouters, userRouters, robotsRouters, orderRouters, pmsRouters, finance, logistics, publish, report, {
  path: '*',
  redirect: '/404'
}];

var createRouter = function createRouter() {
  return new Router({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes,
    mode: 'history'
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;