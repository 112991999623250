var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-tooltip", { attrs: { effect: "dark", placement: "top" } }, [
    _c(
      "div",
      {
        staticClass: "scroll-style",
        staticStyle: {
          "max-width": "400px",
          "max-height": "300px",
          overflow: "auto",
          "line-height": "22px",
        },
        attrs: { slot: "content" },
        slot: "content",
      },
      [_vm._v(_vm._s(_vm.text))]
    ),
    _vm._v(" "),
    _c("div", { class: "text-ellipsis-" + _vm.num }, [
      _vm._v(_vm._s(_vm.text)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }