import { createRouteItem, layoutItem, createMeta } from "./unit.js";

var ReportExport = function ReportExport() {
  return import(
  /* webpackChunkName: 'report-export' */
  '@/views/report/export');
}; //报表导出
//报表管理


export default layoutItem('/report', [//报表导出
createRouteItem('report-export', 'report-export', ReportExport, createMeta('报表导出'))]);