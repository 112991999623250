import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from 'lodash';
export default {
  name: 'BaseSelect',
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: [Array, String, Number, undefined],
      default: ''
    },
    account: {
      type: String,
      default: undefined
    },
    //远程搜索的方法
    query: {
      type: Function,
      default: function _default() {
        return function () {};
      }
    }
  },
  watch: {
    value: function value(newval) {
      !_.isEqual(newval, this.selectValue) && (this.selectValue = newval);
    },
    selectValue: function selectValue(newval) {
      !_.isEqual(newval, this.value) && this.$emit('input', newval);
    },
    account: function account(newval) {
      for (var i = 0; i < this.options.length; i++) {
        var element = this.options[i].account_code;

        if (element == newval) {
          this.selectValue = this.options[i].value;
          return true;
        } else {
          this.selectValue = '';
        }
      }
    }
  },
  data: function data() {
    return {
      selectValue: this.value
    };
  },
  methods: {
    //远程搜索的方法
    remoteMethod: _.debounce(function (val) {
      this.query && this.query(val);
    }, 500)
  }
};