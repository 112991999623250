var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "img-item flex flex-aic flex-jcc pointer" }, [
    _c("img", { attrs: { src: "" + _vm.domainurl + _vm.url, alt: "" } }),
    _vm._v(" "),
    _vm.showMark
      ? _c("div", { staticClass: "flex flex-aic flex-jcc" }, [
          _vm.zoom
            ? _c("span", {
                staticClass:
                  "iconfont icon-fangda1 white-color m-r-10 font-size-20",
                on: { click: _vm.look },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.remove
            ? _c("span", {
                staticClass: "iconfont icon-delete white-color font-size-20",
                on: {
                  click: function ($event) {
                    return _vm.$emit("remove")
                  },
                },
              })
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }