import _slicedToArray from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import _defineProperty from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

var _mutations;

/**
 * TODO 用户信息
 */
import { USER_INFO, SET_DEFAULT_STOCK } from '@/store/types';
import { user } from '@/api/user';
import { defaultstock } from '@/api/stock';
import { TENANT_ID, USER_NAME } from '@/core/types';
import { setLocal } from '@/core/provide';
var state = {
  info: null,
  //用户信息 默认是没有的
  customer_code: '',
  //买家编号
  defaultStock: null //默认仓库

};
var mutations = (_mutations = {}, _defineProperty(_mutations, USER_INFO, function (state, payload) {
  state.info = payload;
}), _defineProperty(_mutations, "setCustomerode", function setCustomerode(state, payload) {
  state.customer_code = payload;
}), _defineProperty(_mutations, SET_DEFAULT_STOCK, function (state, payload) {
  state.defaultStock = payload;
}), _mutations);

var actions = _defineProperty({}, USER_INFO, function (_ref) {
  var commit = _ref.commit,
      state = _ref.state;
  if (state.info) return Promise.resolve();
  return Promise.all([user(), defaultstock()]).then(function (_ref2) {
    var _ref3 = _slicedToArray(_ref2, 2),
        _ref3$ = _ref3[0],
        code = _ref3$.code,
        data = _ref3$.data,
        _ref3$2 = _ref3[1],
        code1 = _ref3$2.code,
        data1 = _ref3$2.data;

    if (code === SUCCESSCODE) {
      setLocal(TENANT_ID, data.tenant_id);
      setLocal(USER_NAME, data.user_name);
      commit(USER_INFO, data);
    } else {
      return Promise.reject();
    }

    if (code1 === SUCCESSCODE) {
      commit(SET_DEFAULT_STOCK, data1);
    } else {
      return Promise.reject();
    }
  });
});

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};