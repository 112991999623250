import _slicedToArray from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/es6.array.from";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.map";
import Vue from 'vue';
var filterMap = new Map([// 1|2类型的 判断1 或者2
['options12', function (value) {
  var text1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '是';
  var text2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '否';
  return value ? parseFloat(value) === 1 ? text1 : text2 : value;
}], ['price', function (value) {
  var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  return parseFloat(value || 0).toFixed(decimal);
}], ['value2Label', function (value, options) {
  if (value === '' || value === null || typeof value === 'undefined') {
    return value;
  }

  for (var i = 0, len = options.length; i < len; i++) {
    if (value === options[i].value) {
      return options[i].label;
    }
  }

  return value;
}], ['empty', function (value) {
  if (value === '' || value === null || typeof value === 'undefined') {
    return '——';
  } else {
    return value;
  }
}]]);
Array.from(filterMap.entries()).forEach(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      id = _ref2[0],
      fn = _ref2[1];

  return Vue.filter(id, fn);
});