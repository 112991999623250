import "core-js/modules/es6.array.find";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from 'lodash';
import { transTabTitle } from '@/core/get-page-title';
export default {
  name: 'crumbs',
  props: ['crumbs'],
  methods: {
    goRoute: function goRoute(name) {
      if (name) {
        this.$router.push({
          name: name
        });
      }
    },
    //这里面的路由会分为两种 一种是菜单的路由 一种是vue 的route
    getRouteTitle: function getRouteTitle(crumb, index) {
      return crumb.meta ? transTabTitle(crumb) || (_.find(this.$store.state.app.menus, {
        uri: crumb.name
      }) || {}).name || crumb.meta.title : crumb.name;
    }
  }
};