import _typeof from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import dialog from "./dialog.vue";

var _loop = function _loop() {};
/**
 * TODO 弹窗的默认配置
 * 
 * @title String|Vnodes
 * @content String|Vnodes
 * @btns 按钮列表  {label,type,class}  label 按钮文案  type按钮类型 class 按钮的class
 * @callback 按钮点击事件   参数(index[Number],obj[Object])
 * @showSure [showSure=true] 是否显示确认按钮
 * @showCancel [showCancel=true] 是否显示取消按钮
 * @sureLabel [sureLabel='确认'] 确认按钮文案
 * @cancelLabel [cancelLabel='取消'] 取消按钮文案
 * @destroy destroy 弹窗销毁时候的回调函数
 * @dialogParams element-ui dialog组件属性  具体参考element-ui 官网dialog组件
 */


var _dafaultSetting = {
  title: '提示',
  //标题
  content: '',
  //内容
  btns: [],
  showSure: true,
  //默认显示确认按钮
  showCancel: true,
  //默认显示取消按钮
  sureLabel: '确认',
  cancelLabel: '取消',
  destroy: function destroy() {},
  sure: _loop,
  //确认的回调
  cancel: _loop,
  //取消的回调
  props: {} //查看 el-dialog

};
/**
 * 
 * TODO 自定义弹窗
 * js直接调用  element-ui那个 messageBox 弹窗对于传入 表单组件来说有问题  会保存之前一次的状态  每次销毁的时候不会删除掉那个弹窗
 * 
 * 这里主要是一个地方   如果btns传了  就是自定义的按钮组合 每个按钮给一个 回调函数 callback
 * 
 * 不给的话默认就是确认的回调跟取消的回调
 *
 *  */

export default {
  install: function install(Vue, watcher) {
    /**
     * TODO 引导页
     * @param params 
     * **/
    var cusDialog = Vue.extend(dialog);

    Vue.prototype.$$dialog = function (params) {
      var options = Object.assign(_.cloneDeep(_dafaultSetting), params);

      if (!options.btns.length) {
        if (options.showCancel) {
          options.btns.push({
            label: options.cancelLabel,
            type: '',
            callback: options.cancel
          });
        }

        if (options.showSure) {
          options.btns.push({
            label: options.sureLabel,
            type: 'primary',
            callback: options.sure
          });
        }
      } //如果有的话那就显示 如果没有的话就不显示


      var vm = new cusDialog({
        data: function data() {
          return Object.assign(options, {
            isRemove: false
          });
        },
        methods: {
          getPromise: function getPromise(val) {
            if (val && _typeof(val) === 'object' && (val instanceof Promise || typeof val.then === 'function')) {
              return val;
            }

            return Promise.resolve(val);
          },
          //按钮点击事件
          handelClick: function handelClick(item) {
            var _this = this;

            var callback = item.callback;

            if (callback) {
              //只要return false 的话  就不关闭  也可以返回一个promise
              this.getPromise(callback(this)).then(function (open) {
                if (open !== false) {
                  return _this.close();
                }
              });
            } else {
              return this.close();
            }
          },
          //关闭函数
          close: function close() {
            this.visible = false;
          },
          remove: function remove() {
            //如果已经删除过或则是个模态弹窗的话 那么就不删除
            if (this.isRemove) return;
            this.isRemove = true;
            document.body.removeChild(this.$el);
            this.$destroy();
          },
          show: function show() {
            this.visible = true;
          }
        },
        created: function created() {
          var _this2 = this;

          //这里是因为页面操作的时候   这个组件是没有办法监听得到router的变化的 所有只能通过外部来通知变化
          watcher.$once('router-change', this.remove);
          this.$once('hook:beforeDestroy', function () {
            watcher.$off('router-change', _this2.remove);
          });
        },
        //销毁函数
        beforeDestroy: function beforeDestroy() {
          options.destroy();
          vm = options = null;
        }
      });
      var vmel = vm.$mount().$el;
      document.body.appendChild(vmel);
      return vm;
    };
  }
};