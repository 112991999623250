//
//
//
//
//
//
//
//
//
//
export default {
  name: 'BaseLink',
  props: {
    to: {
      type: [String, Object],
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};