import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
import { brandsearch } from '@/api/product';
export default {
  name: 'BaseSearchBrand',
  props: ['width', 'value', 'placeholder'],
  watch: {
    brand: function brand(val) {
      this.$emit('input', val);
    },
    value: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        var _this = this;

        if (!val) {
          this.brand = '';
        } else {
          var isHas = false;
          this.brand = val;
          this.brandData.forEach(function (row) {
            if (row.brand_code == val) {
              isHas = true;
            }
          });

          if (!isHas) {
            brandsearch({
              keyword: this.brand
            }, false).then(function (_ref) {
              var data = _ref.data;
              _this.brandData = data.list;
            });
          }
        }
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      timer: null,
      brand: '',
      brandData: []
    };
  },
  created: function created() {
    var _this2 = this;

    brandsearch({
      keyword: this.value
    }, false).then(function (_ref2) {
      var data = _ref2.data;
      _this2.brandData = data.list;
      _this2.loading = false;
    });
  },
  methods: {
    brandChange: function brandChange() {
      this.$emit('change', this.brand, this.brandData);
    },
    remoteMethod: function remoteMethod(query) {
      var vm = this;

      if (query !== '') {
        this.loading = true;
        clearTimeout(this.timer);
        this.timer = setTimeout(function () {
          brandsearch({
            keyword: query
          }, false).then(function (_ref3) {
            var data = _ref3.data;
            vm.brandData = data.list;
            vm.loading = false;
          });
        }, 300);
      } else {
        this.brandData = [];
      }
    }
  }
};