import { createRouteItem, layoutItem, createMeta } from "./unit.js";

var ProductList = function ProductList() {
  return import('@/views/product/list');
}; //商品管理


var GoodsList = function GoodsList() {
  return import('@/views/product/goods');
}; //产品管理


var ProductCreate = function ProductCreate() {
  return import('@/views/product/create');
}; //商品管理


var ProductSpecList = function ProductSpecList() {
  return import('@/views/product/specification');
}; //商品管理


var ProductBrand = function ProductBrand() {
  return import('@/views/product/brand');
}; //品牌管理


var ProductSalePriceCalculation = function ProductSalePriceCalculation() {
  return import('@/views/product/sale-price-calculation');
}; //本店售价计算表


var ProductCategory = function ProductCategory() {
  return import('@/views/product/category');
}; //分类管理


var ProductCategoryMapping = function ProductCategoryMapping() {
  return import('@/views/product/category-mapping');
}; //映射网站分类


var ProductImportList = function ProductImportList() {
  return import('@/views/product/import');
}; // 商品导入结果列表


var ProductQueueList = function ProductQueueList() {
  return import('@/views/product/listing-queue');
}; // 商品刊登队列


var ProductAttrCfg = function ProductAttrCfg() {
  return import(
  /* webpackChunkName: 'product-attr-cfg' */
  '@/views/product/attr-cfg/index.vue');
}; // 默认描述属性配置


var ProductTaxCfg = function ProductTaxCfg() {
  return import(
  /* webpackChunkName: 'product-tax-cfg' */
  '@/views/product/tax-cfg');
}; // 报关信息配置


var ProductDeclarationElement = function ProductDeclarationElement() {
  return import(
  /* webpackChunkName: 'product-declaration-element' */
  '@/views/product/tax-cfg/declaration-element');
}; // 申报要素


var SizeGuideHead = function SizeGuideHead() {
  return import('@/views/product/size-guide/head');
};

var SizeGuideTemplate = function SizeGuideTemplate() {
  return import('@/views/product/size-guide/template');
};

var ProductModelLibrary = function ProductModelLibrary() {
  return import('@/views/product/model-library');
}; //模特库


var ProductModelLibraryCreate = function ProductModelLibraryCreate() {
  return import('@/views/product/model-library-create');
}; //添加模特


var ProductModelFitting = function ProductModelFitting() {
  return import('@/views/product/model-fitting');
}; //试衣模特


var WashProtectManagment = function WashProtectManagment() {
  return import('@/views/product/wash-protect');
}; // 洗护管理


var WashProtectTemplate = function WashProtectTemplate() {
  return import('@/views/product/wash-protect-template');
}; // 洗护模板


var WashProtectTemplateCreate = function WashProtectTemplateCreate() {
  return import('@/views/product/wash-protect-template/create');
}; // 洗护模板编辑


var PackagingAsk = function PackagingAsk() {
  return import('@/views/product/packaging-ask');
}; // 包装辅料要求


var MarkUpSetting = function MarkUpSetting() {
  return import('@/views/product/mark-up-setting');
}; // 出货价加价配置


var GroupRuleList = function GroupRuleList() {
  return import("@/views/product/group-rule");
}; // 分组规则


var AccessoriesList = function AccessoriesList() {
  return import('@/views/product/accessories');
};

var AccessoriesCreate = function AccessoriesCreate() {
  return import('@/views/product/accessories/create');
};

var AccessoriesCategory = function AccessoriesCategory() {
  return import('@/views/product/accessories-category');
};

var AccessoriesComponent = function AccessoriesComponent() {
  return import('@/views/product/accessories-component');
};

var ProductSuitList = function ProductSuitList() {
  return import("@/views/product/suit");
}; // 产品套装


var ProductSuitDetail = function ProductSuitDetail() {
  return import("@/views/product/suit/detail");
}; // 产品套装明细


var ProductNewAttribute = function ProductNewAttribute() {
  return import("@/views/product/new-attribute/index.vue");
}; // 商品属性管理


var level2CategoryBindNewAttribute = function level2CategoryBindNewAttribute() {
  return import("@/views/product/new-attribute/level2-category-bind-new-attribute.vue");
}; // 创建二级分类属性页面


var newAttributeManagerCategory = function newAttributeManagerCategory() {
  return import("@/views/product/new-attribute/manager-category.vue");
}; // 创建二级分类属性页面


var level2CategoryNewAttributeList = function level2CategoryNewAttributeList() {
  return import("@/views/product/new-attribute/level2-category-new-attribute-list.vue");
};

var SkcModelList = function SkcModelList() {
  return import("@/views/product/skc-model");
};

var SkcApprovalList = function SkcApprovalList() {
  return import("@/views/product/skc-approval");
}; //商品相关


export default layoutItem('/product', [//商品列表
createRouteItem('list', 'product-list', ProductList), //产品列表
createRouteItem('goods-list', 'goods-list', GoodsList), //添加产品
createRouteItem('create', 'product-create', ProductCreate, createMeta('产品添加', 'product-list', '', {
  cacheView: false
})), //导入列表
createRouteItem('import', 'product-import-list', ProductImportList, createMeta('商品导入结果', 'product-list')), //规格列表
createRouteItem('spec', 'product-spec-list', ProductSpecList), //分类管理
createRouteItem('category', 'product-category', ProductCategory), //分类管理
createRouteItem('category-mapping', 'product-category-mapping', ProductCategoryMapping, createMeta('映射网站分类', 'product-category')), //商品刊登队列
createRouteItem('queue-list', 'product-queue-list', ProductQueueList, createMeta('刊登队列', 'product-list')), //品牌列表
createRouteItem('brand', 'product-brand', ProductBrand), //本店售价计算表
createRouteItem('sale-price-calculation', 'sale-price-calculation', ProductSalePriceCalculation), //默认描述属性配置
createRouteItem('attr-cfg', 'product-attr-cfg', ProductAttrCfg, createMeta('默认描述属性配置')), //报关信息配置
createRouteItem('tax-cfg', 'product-tax-cfg', ProductTaxCfg, createMeta('报关信息配置')), createRouteItem('declaration-element', 'product-declaration-element', ProductDeclarationElement, createMeta('申报要素配置')), // 尺码表头
createRouteItem('size-guide-head', 'size-guide-head', SizeGuideHead, createMeta('尺码表头配置')), // 尺码模板
createRouteItem('size-guide-template', 'size-guide-template', SizeGuideTemplate, createMeta('尺码模板配置')), //模特库
createRouteItem('model-library', 'product-model-library', ProductModelLibrary, createMeta('模特库')), //添加模特
createRouteItem('model-library-create', 'product-model-library-create', ProductModelLibraryCreate, createMeta('添加模特', 'product-model-library')), //试衣模特
createRouteItem('model-fitting', 'product-model-fitting', ProductModelFitting, createMeta('试衣模特')), // 洗护管理
createRouteItem('wash-protect', 'wash-protect', WashProtectManagment, createMeta('洗护管理')), // 洗护模板管理
createRouteItem('wash-protect-list', 'wash-protect-template', WashProtectTemplate, createMeta('洗护模板管理')), // 洗护模板管理-编辑/新增
createRouteItem('wash-create', 'wash-protect-template-create', WashProtectTemplateCreate, createMeta('新增洗护模板', 'wash-protect-template', '', {
  cacheView: false
})), // 分组规则管理
createRouteItem('group-rule', 'group-rule', GroupRuleList), // 包装辅料要求
createRouteItem('packaging-ask', 'product-packaging-ask', PackagingAsk, createMeta('包装辅料要求')), // 出货价加价配置
createRouteItem('mark-up-setting', 'product-mark-up-setting', MarkUpSetting, createMeta('出货价加价配置')), // 面辅料列表
createRouteItem('accessories-list', 'product-accessories-list', AccessoriesList, createMeta('面辅料列表')), // 面辅料新增/编辑
createRouteItem('accessories-create', 'product-accessories-create', AccessoriesCreate, createMeta('新增面辅料', 'product-accessories-list')), // 面辅料分类
createRouteItem('accessories-category', 'product-accessories-category', AccessoriesCategory, createMeta('面辅料分类')), // 面辅料成分
createRouteItem('accessories-component', 'product-accessories-component', AccessoriesComponent, createMeta('面辅料成分')), // 产品套装
createRouteItem('product-suit', 'product-suit', ProductSuitList, createMeta('产品套装')), // 产品套装明细
createRouteItem('product-suit-detail', 'product-suit-detail', ProductSuitDetail, createMeta('产品套装明细', 'product-suit')), // 商品规格管理
createRouteItem('product-new-attribute', 'product-new-attribute', ProductNewAttribute, createMeta('属性管理')), createRouteItem('level2-category-bind-new-attribute', 'level2-category-bind-new-attribute', level2CategoryBindNewAttribute, createMeta('低级属性组列表', 'product-new-attribute')), createRouteItem('new-attribute-manage-category', 'new-attribute-manage-category', newAttributeManagerCategory, createMeta(' 分类绑定属性组', 'product-new-attribute')), createRouteItem('level2-category-new-attribute-list', 'level2-category-new-attribute-list', level2CategoryNewAttributeList, createMeta('低级属性组列表')), // SKC模特管理
createRouteItem('skc-model', 'skc-model', SkcModelList), // 审版列表
createRouteItem('skc-approval', 'skc-approval', SkcApprovalList, createMeta('审版列表'))]);