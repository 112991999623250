export var USER_INFO = 'USER_INFO'; //用户信息

export var COMMAN_CONFIG = 'COMMAN_CONFIG'; //添加配置

export var MENUS_ROLES = 'MENUS_ROLES'; //角色菜单权限

export var PUSH_CACHE_TAB = 'PUSH_CACHE_TAB'; //添加缓存菜单

export var SPLICE_CACHE_TABLE = 'SPLICE_CACHE_TABLE'; //删除缓存菜单

export var CACHE_TAB_CLICK = 'CACHE_TAB_CLICK'; //缓存菜单点击

export var NEXT_REFRESH_NAME = 'NEXT_REFRESH_NAME'; //缓存菜单点击

export var SET_DEFAULT_STOCK = 'SET_DEFAULT_STOCK'; //设置用户默认仓库

export var RATES = 'RATES'; //缓存菜单点击