var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-input",
    _vm._g(
      _vm._b(
        {
          staticClass: "custom-base-input",
          attrs: { size: _vm.size, placeholder: _vm.placeholder },
          model: {
            value: _vm.input,
            callback: function ($$v) {
              _vm.input = $$v
            },
            expression: "input",
          },
        },
        "el-input",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("suffix", null, { slot: "suffix" })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }